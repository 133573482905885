/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "./draftjs-editor.sass"

const DraftJsEditor = ({
    state,
    syncEditorState,
    toolbar,
    readOnly,

}) => {
    return (
        <div className="DraftJsEditor" data-readonly={readOnly}>
            <Editor
                readOnly={readOnly}
                toolbar={toolbar}
                placeholder={
                    !readOnly
                        ? "Type something OR move generated suggestions here to edit them..."
                        : ""
                }
                editorState={state}
                onEditorStateChange={syncEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
            />
        </div>
    )
}
export default DraftJsEditor
