import { mergeMovedContent } from "content-studio/src/features/content-editor"
import { selectCurrentProject } from "content-studio/src/features/project"
import { updateResultProp } from "content-studio/src/features/results"
import React, { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"

const useSuggestionOperations = (
    content,
    dispatch,
) => {
    const EXPANDED_TEXT_THRESHOLD = 180
    const [textCopied, setTextCopied] = useState(false)
    const [suggestion, setSuggestion] = useState(content)
    const [expandable, setExpandable] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false)
    const currentProject = useSelector(selectCurrentProject)
    const updateFavoriteStatus = () => {
        const toggledFavStatus = suggestion?.isFavourite ? 0 : 1
        dispatch(
            updateResultProp({
                projectId:currentProject?._id,
                _id: suggestion?._id,
                isFavourite: toggledFavStatus,
            }),
        )
    }

    const removeSuggestion = () => {
        dispatch(
            updateResultProp({
                projectId:currentProject?._id,
                _id: suggestion?._id,
                isDeleted: 1,
            }),
        )
    }

    const copyToContentEditor = () => {
        dispatch(
            mergeMovedContent({
                type: suggestion?.type,
                content: suggestion?.content,
            }),
        )
        dispatch(
            updateResultProp({
                projectId:currentProject?._id,
                _id: suggestion?._id,
                isUsed: suggestion?.isUsed + 1,
            }),
        )
    }

    const copyToClipboard = () => {
        const text = suggestion?.content ?? ""
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text)
            return
        }
        navigator.clipboard.writeText(text).then(
            () => setTextCopied(true),
            () => setTextCopied(false),
        )
    }

    const fallbackCopyTextToClipboard = (text) => {
        var textArea = document.createElement("textarea")
        textArea.value = text

        // Avoid scrolling to bottom
        textArea.style.top = "0"
        textArea.style.left = "0"
        textArea.style.position = "fixed"

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
            const copyStatus = document.execCommand("copy")
            if (!copyStatus) return
            setTextCopied(true)
        } catch (err) {
            setTextCopied(false)
        }

        document.body.removeChild(textArea)
    }

    const getFormattedContent = () => {
        if (!suggestion) return
        switch (suggestion?.type) {
            case "list-bullet":
                return (
                    <ul className="su-listBullet">
                        {suggestion?.content
                            ?.split(/\n/g)
                            .map((item, index) => {
                              return item && <li key={index}>{item}</li>
                            }
                            )}
                    </ul>
                )

            case "list-number":
                return (
                    <ol className="su-listNumber">
                        {suggestion?.content
                            ?.split(/\n/g)
                            .map((item, index) =>  {
                                return item && <li key={index}>{item}</li>
                              })}
                    </ol>
                )

            case "single-bullet":
                return (
                    <ul className="su-listBulletSingle">
                        {suggestion?.content
                            ?.split(/\n/g)
                            .map((item, index) =>  {
                                return item && <li key={index}>{item}</li>
                              })}
                    </ul>
                )

            case "title":
                return <div className="su-title">{suggestion?.content}</div>

            case "heading":
                return <div className="su-heading">{suggestion?.content}</div>

            default:
                return (
                    <React.Fragment>
                        {suggestion?.content?.split(/\n/g).map((item, index) => {
                            return item && <p key={index}>{item}</p>
                        }
                        )}
                    </React.Fragment>
                )
        }
    }

    useEffect(() => {
        setSuggestion(content)
        setExpandable(content?.content?.length >= EXPANDED_TEXT_THRESHOLD)
    }, [content])

    return {
        textCopied,
        setTextCopied,
        updateFavoriteStatus,
        copyToContentEditor,
        copyToClipboard,
        getFormattedContent,
        removeSuggestion,
        expandable,
        isExpanded,
        setIsExpanded,
    }
}

export default useSuggestionOperations
