/**
 * Initial state
 */
export const initialState = {
    listVisibility: false,
    userSelection: {},
    cursor: 0,
    searchQuery: "",
    buttonUID: "",
    list: [],
    refs: {
        list: null,
    },
    error: {
        status: false,
        message: "",
    },
}
/**
 * Reducer function
 */
export const reducer = (state, action) => {
    switch (action.type) {
        case ACTION.SHOW_LIST:
            return { ...state, listVisibility: true }

        case ACTION.HIDE_LIST:
            return { ...state, listVisibility: false }

        case ACTION.INCREMENT_CURSOR:
            return { ...state, cursor: state.cursor + 1 }

        case ACTION.DECREMENT_CURSOR:
            return { ...state, cursor: state.cursor - 1 }

        case ACTION.RESET_CURSOR:
            return { ...state, cursor: 0 }

        case ACTION.UPDATE_CURSOR:
            return { ...state, cursor: action.payload }

        case ACTION.UPDATE_USER_SELECTION:
            return { ...state, userSelection: action.payload }

        case ACTION.UPDATE_SEARCH_QUERY:
            return { ...state, searchQuery: action.payload }

        case ACTION.RESET_SEARCH_QUERY:
            return { ...state, searchQuery: "" }

        case ACTION.UPDATE_LIST:
            return { ...state, list: action.payload }

        case ACTION.UPDATE_BUTTON_UID:
            return { ...state, buttonUID: action.payload }

        case ACTION.UPDATE_LIST_REF:
            return {
                ...state,
                refs: {
                    ...state.refs,
                    list: action.payload,
                },
            }

        case ACTION.THROW_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    status: true,
                    message: action.payload,
                },
            }

        case ACTION.CLEAR_ERROR:
            return {
                ...state,
                error: { ...state.error, status: false, message: "" },
            }

        default:
            throw new Error("Problematic Action: ", action)
    }
}
/**
 * Reducer Actions
 */
export const ACTION = {
    SHOW_LIST: "show-list",
    HIDE_LIST: "hide-list",
    INCREMENT_CURSOR: "increment-cursor",
    DECREMENT_CURSOR: "decrement-cursor",
    RESET_CURSOR: "reset-cursor",
    UPDATE_CURSOR: "update-cursor",
    UPDATE_USER_SELECTION: "update-user-selection",
    UPDATE_SEARCH_QUERY: "update-search-query",
    RESET_SEARCH_QUERY: "reset-search-query",
    UPDATE_LIST: "update-list",
    UPDATE_BUTTON_UID: "update-button-uid",
    UPDATE_LIST_REF: "update-list-ref",
    THROW_ERROR: "throw-error",
    CLEAR_ERROR: "clear-error",
}
