import React, { useRef, useEffect } from "react"
import { getResultsMeta } from "content-studio/src/features/results"
import {
    fetchResultsHistory,
    HistoryList,
} from "content-studio/src/features/history"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentMicroUsecase } from "content-studio/src/features/micro-usecase"
import "./history-sidebar.sass"

const HistorySidebar = ({ currentStep, currentProject, projectStatus }) => {
    const currentMicroUsecase = useSelector(getCurrentMicroUsecase)
    const metaData = useSelector(getResultsMeta)

    const resultsMetadata = Object.keys(metaData ?? {})?.length
        ? metaData
        : currentProject?.document?.result?.metaData

    const resultsCount = Object.keys(resultsMetadata ?? {})?.length
        ? resultsMetadata[currentMicroUsecase?._id]
        : 0

    // const historyCount = resultsMetadata
    //     ? Object.values(resultsMetadata).reduce((acc, val) => acc + val)
    //     : 0

    const historyCount = 0 // TODO: Update it like we are updating resultsCount

    const tabsContainerRef = useRef(null)
    const scrollableListRef = useRef(null)
    const dispatch = useDispatch()
    /**
     * Using this to map tabs with their corresponding icons
     * based on tab's name
     */
    const setTabsContainerScroll = () => {
        const winHeight = window.innerHeight
        const yOffset = tabsContainerRef.current?.offsetTop
        const containerHeight = winHeight - yOffset
        /**
         * Tab container height should be absolute with value equivalent to
         * the difference obtained when we subtract tab container's top
         * offset from the window's overall height
         */
        tabsContainerRef.current.style.height = containerHeight + "px"
    }

    useEffect(() => {
        /**
         * If current step is "History" tab, we fetch results
         * history if not already fetched
         */
        if (!currentProject?._id) return
        dispatch(fetchResultsHistory(currentProject?._id))
    }, [currentProject?._id, dispatch])

    useEffect(() => {
        /**
         * Fix to clear the text selection that's happening
         * when opens sidebar for first time
         */
        window.getSelection()?.removeAllRanges()
    }, [])

    useEffect(() => {
        setTabsContainerScroll()
    }, [])

    return (
        <div className="HistorySidebar">
            <div className="TabsGroup">Results History</div>
            <div className="TabsContainer" ref={tabsContainerRef}>
                <div className="TabBody" ref={scrollableListRef}>
                    <HistoryList
                        projectStatus={projectStatus}
                        isBlankProject={!currentProject?._id}
                    />
                </div>
            </div>
        </div>
    )
}

export default HistorySidebar
