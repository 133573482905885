import React, { useRef } from "react"
import { useEffect } from "react"
import "./radio-input.sass"

const RadioInput = ({
    name,
    label,
    readOnly,
    defaultValue,
    value,
    onClick,
    radioInputRef
}) => {

    useEffect(() => {
        Array.from(radioInputRef.current.querySelectorAll("input")).forEach((el) => {
            el.checked = el?.value === value
        })
    }, [value])
    return (
        <div className="RadioInput">
            <fieldset name="radio" className="RadioFieldSet" ref={radioInputRef}>
                <div className="TextFieldLabel">{label}</div>
                {defaultValue?.map((defaultValueItem) => (
                    <div className="InputRadio">
                        <input
                            onClick={onClick}
                            type="radio"
                            id={defaultValueItem}
                            name={name}
                            value={defaultValueItem}
                            disabled={readOnly}
                        />
                        <label htmlFor={defaultValueItem}>
                            {defaultValueItem === "ordered"
                                ? "Numbered list"
                                : "Bulleted list"}
                        </label>
                    </div>
                ))}
            </fieldset>
        </div>
    )
}

export default RadioInput
