export const PROJECT_STATUS = {
    ACTIVE: "active",
    ARCHIVE: "archive",
    TRASH: "trash",
}

export const PROJECT_TYPE = {
    BLANK: "blank",
    EXISTING: "existing",
}
