import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "./usecase-list-item.sass"
import { FiCheckCircle } from "react-icons/fi"

const UsecaseListItem = ({
    item,
    isActive,
    isDisabled,
    hasCursor,
    hasUsed,
    onClickCallback,
    onFocusCallback,
    onDeactivateCallback,
}) => {
    const [focus, setFocus] = useState(hasCursor)
    const [active] = useState(isActive)
    const [disable] = useState(isDisabled)

    useEffect(() => {
        setFocus(hasCursor)
    }, [hasCursor])

    useEffect(() => {
        onFocusCallback(focus, item)
    }, [focus, item])

    return (
        <div
            data-testid="usecase-list-item"
            tabIndex="-1"
            className="UsecaseListItem"
            data-focus={focus}
            data-active={active}
            data-disable={disable}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
            onClick={() => onClickCallback(item)}
            title={item?.description}>
            <div className="lhs">
                {item?.icon ? (
                    <img
                        className="usecase-icon"
                        src={`/assets/icons/usecase/${item.icon}.svg`}
                        alt={item?.description}
                    />
                ) : null}
                <div className="text">{item?.name}</div>
            </div>
            <div className="rhs">{hasUsed ? <FiCheckCircle /> : null}</div>
        </div>
    )
}

UsecaseListItem.propTypes = {
    item: PropTypes.object.isRequired,
    hasUsed: PropTypes.bool,
    hasCursor: PropTypes.bool,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onClickCallback: PropTypes.func,
    onFocusCallback: PropTypes.func,
    onDeactivateCallback: PropTypes.func,
}

UsecaseListItem.defaultProps = {
    isActive: false,
    isDisabled: false,
    hasCursor: false,
    hasUsed: false,
    onClickCallback: () => {},
    onFocusCallback: () => {},
    onDeactivateCallback: () => {},
}

export default UsecaseListItem
