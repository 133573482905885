import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosHttp } from "content-studio/src/utils"
import { projectApi } from "content-studio/src/api"
const { REACT_APP_URI_PROJECT: URI_PROJECT } = process.env

const defaultState = {
    status: "idle", // "idle" | "loading" | "succeeded" | "failed"
    collectionMap: {},
    error: "",
}

export const fetchUserFiles = createAsyncThunk(
    "userFiles/fetchUserFiles",
    async (config, thunkAPI) => {
        //Todo: need to change limit
        const { limit = 100, page = 1, status } = config
        const res = await axiosHttp({
            axiosInstance: projectApi,
            method: "GET",
            url: `${URI_PROJECT}?status=${status}&limit=${limit}&page=${page}`,
        })

        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)

        return res?.data
    },
)

const userFilesSlice = createSlice({
    name: "userFiles",
    initialState: defaultState,
    reducers: {
        updateFileStatus: (state, action) => {
            state.status = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserFiles.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(fetchUserFiles.fulfilled, (state, action) => {
                state.status = "succeeded"

                const map = {}
                const files = action.payload

                files?.forEach((file) => (map[file?._id] = file))
                state.collectionMap = map
            })
            .addCase(fetchUserFiles.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.payload
            })
            .addCase("project/createNewProject/fulfilled", (state, action) => {
                const newFile = action.payload
                state.collectionMap[newFile?._id] = newFile
                state.status = "idle"
            })
            .addCase(
                "project/fetchSingleProject/fulfilled",
                (state, action) => {
                    const newFile = action.payload
                    state.collectionMap[newFile?._id] = newFile
                    state.status = "idle"
                },
            )
            .addCase(
                "project/fetchSingleProjectDocument/fulfilled",
                (state, action) => {
                    const { _id, document } = action.payload
                    /*
                        Todo: need to check it giving error
                        Maybe unable to unable project with given id
                    */
                    // const project = state.active[_id]

                    // if (!project) return

                    // project.document = document
                    state.status = "idle"
                },
            )
            .addCase("project/updateProjectProp/fulfilled", (state, action) => {
                const newFile = action.payload
                state.collectionMap[newFile?._id] = newFile
                state.status = "idle"
            })
            .addCase("project/updateProject/fulfilled", (state, action) => {
                const newFile = action.payload
                state.collectionMap[newFile?._id] = newFile
                state.status = "idle"
            })
            .addCase(
                "project/updateListedProject/fulfilled",
                (state, action) => {
                    const newFile = action.payload
                    state.collectionMap[newFile?._id] = newFile
                    state.status = "idle"
                },
            )
            .addCase(
                "project/updateListedProjectBulk/fulfilled",
                (state, action) => {
                    state.status = "idle"
                },
            )
    },
})

/**
 * TODO: selectALllFiles will always return active file in project component.
 * What if user has opened an archived/trashed file and refreshed the project component?
 * There will be some issues then. Fix this.
 */
export const selectAllFiles = (state) => state.userFiles
export const selectAllArchiveFiles = (state) => state.userFiles.archive
export const selectAllTrashFiles = (state) => state.userFiles.trash
export const getUserFilesStatus = (state) => state.userFiles.status
export const getUserFilesError = (state) => state.userFiles.error
export const getEmptyResponseMap = (state) => state.userFiles.emptyResponseMap

export const { updateFileStatus } = userFilesSlice.actions

export default userFilesSlice.reducer
