import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import "./editor-metrics-sidebar.sass"
import { FiFileText, FiClock, FiEdit3 } from "react-icons/fi"
import EmptyEditorMetricsSidebar from "../empty-editor-metrics-sidebar/empty-editor-metrics-sidebar"

const EditorMetricsSidebar = ({ USECASE_SIDEBAR_TABS }) => {
    const tabsContainerRef = useRef(null)
    /**
     * Using this to map tabs with their corresponding icons
     * based on tab's name
     */
    const tabIcon = {}
    tabIcon[USECASE_SIDEBAR_TABS.CONFIG] = <FiEdit3 />
    tabIcon[USECASE_SIDEBAR_TABS.RESULTS] = <FiFileText />
    tabIcon[USECASE_SIDEBAR_TABS.HISTORY] = <FiClock />

    useEffect(() => {
        const winHeight = window.innerHeight
        const yOffset = tabsContainerRef.current?.offsetTop
        const containerHeight = winHeight - yOffset
        /**
         * Tab container height should be absolute with value equivalent to
         * the difference obtained when we subtract tab container's top
         * offset from the window's overall height
         */
        tabsContainerRef.current.style.height = containerHeight + "px"
    })

    return (
        <div className="EditorMetricsSidebar">
            <div className="TabsContainer" ref={tabsContainerRef}>
                <div className="TabBody">
                    <EmptyEditorMetricsSidebar />
                </div>
            </div>
        </div>
    )
}

EditorMetricsSidebar.propTypes = {
    list: PropTypes.array,
    setProjectId: PropTypes.func,
    currentStep: PropTypes.string,
    switchTabTo: PropTypes.func,
    USECASE_SIDEBAR_TABS: PropTypes.object,
    resultsCount: PropTypes.string,
    iconCollapse: PropTypes.element,
}

export default EditorMetricsSidebar
