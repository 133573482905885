import uniqid from "uniqid"
import md5 from "md5"

let hashInstance

class Hash {
    /**
     * Singleton pattern
     */
    constructor() {
        if (hashInstance) throw new Error("New hash instance cannot be created")

        hashInstance = this
    }

    getRandom() {
        return md5(uniqid())
    }

    getRandomPrefixed(prefix) {
        return prefix + md5(uniqid())
    }

    getUID() {
        return uniqid()
    }

    getUIDPrefixed(prefix) {
        return uniqid(prefix)
    }

    fromString(string) {
        return md5(string)
    }
}

const hash = Object.freeze(new Hash())

export default hash
