import React, { useState } from "react"
import ResultCard from "../result-card/result-card"
import { Button } from "@attrybtech/attryb-ui"
import { ReactComponent as Loader } from "content-studio/src/assets/animated/result-loader.svg"
import "./result-list.sass"
import {
    EmptyResultsList,
    BlankResultsList,
} from "content-studio/src/features/results"
import { useSelector, useDispatch } from "react-redux"
import {
    selectCurrentProject,
} from "content-studio/src/features/project"
import {
    getResultsCollection,
    getResultsStatus,
    fetchNewUseCaseResults
} from "content-studio/src/features/results"
import { getCurrentMicroUsecase } from "content-studio/src/features/micro-usecase"
import { motion, AnimatePresence } from "framer-motion"
import CONFIG from "data/config.json"

const {RESULTS_SCROLLABLE_LIST_ID} = CONFIG

const SLICE_STATUS = {
    LOAD: "loading",
    SUCCESS: "succeeded",
}
const LIST_STATE = { LOAD: "loading", STATIC: "static", SUCCESS: "succeeded" ,IDLE:"idle"}

const ResultsList = () => {
    const dispatch = useDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const currentMicroUsecase = useSelector(getCurrentMicroUsecase)
    const results = useSelector(() =>
        getResultsCollection(currentProject?._id, currentMicroUsecase._id),
    )

    const resultsStatus = useSelector(getResultsStatus)

    const getListState = () => {
        switch (resultsStatus) {
            case SLICE_STATUS.LOAD:
                return LIST_STATE.LOAD

            case SLICE_STATUS.SUCCESS:
                return LIST_STATE.SUCCESS

            default:
                return LIST_STATE.STATIC
        }
    }

    return (
        <div className="ResultRenderer">
            <div className="ResultsList">
                <AnimatePresence>
                    {getListState() === LIST_STATE.LOAD ? (
                        <motion.div
                            className="loading-state"
                            style={{ margin: 0, padding: "2rem 0" }}>
                            <Loader
                                style={{
                                    width: "2rem",
                                    height: "2rem",
                                    margin: "0 auto",
                                }}
                            />
                            <small className="message">
                                Loading results...
                            </small>
                        </motion.div>
                    ) : null}
                </AnimatePresence>
                {results?.length ? (
                    <React.Fragment>
                        {results.map((result) => {
                            return result?.content && !result?.isDeleted ? (
                                <ResultCard key={result?._id} result={result} />
                            ) : null
                        })}
                        <Button
                            loadingIcon="/assets/images/loader.svg"
                            isPressed={getListState() === LIST_STATE.FETCH}
                            state={
                                getListState() === LIST_STATE.FETCH
                                    ? "loading"
                                    : "default"
                            }
                            variant="outline"
                            size="sm"
                            onClick={() => {
                                if (!currentProject?._id) return

                                try{
                                    const resultScroableView = document.getElementById(RESULTS_SCROLLABLE_LIST_ID)
                                    resultScroableView?.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                    });
    
                                }
                                catch(error){}
                               
                                dispatch(
                                    fetchNewUseCaseResults({
                                        projectId: currentProject?._id,
                                        configuration : currentProject.document.usecase.list[currentMicroUsecase?._id],
                                        microUsecaseId:
                                            currentMicroUsecase?._id,
                                    }),
                                )
                            }}>
                            Load More
                        </Button>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {getListState() === LIST_STATE.STATIC && resultsStatus === LIST_STATE.IDLE ? (
                            <BlankResultsList />
                        ) : null}

                        {getListState() === LIST_STATE.SUCCESS &&
                        !results?.length ? (
                            <EmptyResultsList />
                        ) : null}

                        {/* {getListState() === LIST_STATE.LOAD ? (
                            <div
                                className="loading-state"
                                style={{ margin: 0, padding: "2rem 0" }}>
                                <Loader
                                    style={{
                                        width: "2rem",
                                        height: "2rem",
                                        margin: "0 auto",
                                    }}
                                />
                                <small className="message">
                                    Generating results...
                                </small>
                            </div>
                        ) : null} */}
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export default ResultsList
