import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosHttp } from "content-studio/src/utils"
import { projectApi } from "content-studio/src/api"
const { REACT_APP_URI_PROJECT: URI_PROJECT } = process.env

const defaultState = {
    status: "idle", // "idle" | "loading" | "succeeded" | "failed"
    error: "",
    current: {},
    projectType: "", // blank | "existing"
    context: {
        current: "inbox", // inbox | archive | trash
        messaging: {
            archive: {
                message: "This project is Archived. Operations are limited.",
                type: "banner",
                variant: "warning",
            },
            trash: {
                message:
                    "This project is in Trash bin. Need to confirm before removing permanently",
                type: "banner",
                variant: "danger",
            },
        },
    },

    access: {
        readAllowed: true,
        editAllowed: true,
        createAllowed: true,
    },
}

export const createNewProject = createAsyncThunk(
    "project/createNewProject",
    async (config, thunkAPI) => {
        const { payload, dispatch, fetchUsecaseResults } = config
        const res = await axiosHttp({
            axiosInstance: projectApi,
            method: "POST",
            url: URI_PROJECT,
            requestConfig: payload,
        })
        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)
        if (fetchUsecaseResults) {
            dispatch(
                fetchUsecaseResults({
                    projectId: res?.data?._id,
                    microUseCaseId: res?.data?.document.usecase?.current[0],
                }),
            )
        }

        return res?.data
    },
)

export const fetchSingleProject = createAsyncThunk(
    "project/fetchSingleProject",
    async ({ id, documentOnly }, thunkAPI) => {
        if (!id) throw new Error("Undefined id provided to fetch project")
        const res = await axiosHttp({
            axiosInstance: projectApi,
            method: "GET",
            url: `${URI_PROJECT}/${id}?documentonly=${documentOnly}`,
        })

        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)

        return res?.data
    },
)

export const fetchSingleProjectDocument = createAsyncThunk(
    "project/fetchSingleProjectDocument",
    async ({ id }, thunkAPI) => {
        if (!id) throw new Error("Undefined id provided to fetch project")
        const res = await axiosHttp({
            axiosInstance: projectApi,
            method: "GET",
            url: `${URI_PROJECT}/${id}?documentonly=true`,
        })

        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)

        return { document: res?.data, _id: id }
    },
)

export const updateProjectProp = createAsyncThunk(
    "project/updateProjectProp",
    async (config, thunkAPI) => {
        const { _id } = config

        if (!_id) throw new Error("Undefined 'id' provided to fetch project")

        const res = await axiosHttp({
            axiosInstance: projectApi,
            method: "PATCH",
            url: `${URI_PROJECT}/${_id}`,
            requestConfig: config,
        })

        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)

        return res?.data
    },
)
export const updateListedProject = createAsyncThunk(
    "project/updateListedProject",
    async (config, thunkAPI) => {
        const { _id } = config

        if (!_id) throw new Error("Undefined 'id' provided to fetch project")

        const res = await axiosHttp({
            axiosInstance: projectApi,
            method: "PATCH",
            url: `${URI_PROJECT}/${_id}`,
            requestConfig: config,
        })

        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)

        return res?.data
    },
)

export const updateListedProjectBulk = createAsyncThunk(
    "project/updateListedProjectBulk",
    async (config, thunkAPI) => {
        const { ids,method="PATCH" } = config
        
        const res = await axiosHttp({
            axiosInstance: projectApi,
            method: method,
            url: `${URI_PROJECT}/bulk`,
            requestConfig: config,
        })

        return res
    },
)

/**
 * For updating project's name, usecase and content
 */
export const updateProject = createAsyncThunk(
    "project/updateProject",
    async (config, thunkAPI) => {
        const { payload, dispatch, fetchUsecaseResults = null } = config

        if (!payload?._id)
            throw new Error("Undefined 'id' provided to fetch project")

        const res = await axiosHttp({
            axiosInstance: projectApi,
            method: "PUT",
            url: `${URI_PROJECT}/${payload?._id}`,
            requestConfig: payload,
        })

        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)

        if (fetchUsecaseResults) {
            dispatch(
                fetchUsecaseResults({
                    projectId: res?.data?._id,
                    microUseCaseId: res?.data?.document.usecase?.current[0],
                }),
            )
        }

        return res?.data
    },
)

/**
 * This is project slice and holds a global state
 * related to everything project.
 */
const projectSlice = createSlice({
    name: "project",
    initialState: defaultState,
    reducers: {
        resetStateStaus: (state) => {
            state.status = "idle"
        },
        clearCurrentProject: (state) => {
            state.current = {}
        },
        updateProjectFromCache: (state, action) => {
            state.current = action.payload
        },
        updateProjectType: (state, action) => {
            state.projectType = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            /**
             * Handling cases when we are fetching a full single project
             */
            .addCase(fetchSingleProject.pending, (state) => {
                state.status = "loading"
            })
            .addCase(fetchSingleProject.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.payload
            })
            .addCase(fetchSingleProject.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.current = action.payload
            })
            /**
             * Handling cases when we are fetching only a document for
             * a given project
             */
            .addCase(fetchSingleProjectDocument.pending, (state) => {
                state.status = "loading"
            })
            .addCase(fetchSingleProjectDocument.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.payload
            })
            .addCase(fetchSingleProjectDocument.fulfilled, (state, action) => {
                state.status = "succeeded"

                const { _id, document } = action.payload

                // state.current = cache.getProject(_id)
                state.current.document = document
            })
            /**
             * Handling cases when we are creating a new project
             */
            .addCase(createNewProject.pending, (state) => {
                state.status = "loading"
            })
            .addCase(createNewProject.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error?.message
            })
            .addCase(createNewProject.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.current = action.payload
            })
            /**
             * Handling cases when we are updating any given
             * property of the project object
             */
            .addCase(updateProjectProp.pending, (state) => {
                /**
                 * Using different loading name when we are patch updating project
                 * to prevent unnecessary resetting of results list's scroll state
                 * whenever the content is auto-saved
                 */
                state.status = "patch-loading"
            })
            .addCase(updateProjectProp.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.payload
            })
            .addCase(updateProjectProp.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.current = action.payload
            })
            /**
             * Handling cases when we are updating any given
             * property of the project listed in the projects, archive
             * and trash pages
             */
            .addCase(updateListedProject.pending, (state) => {
                state.status = "loading"
            })
            .addCase(updateListedProject.rejected, (state, action) => {
                state.status = "failed"
            })
            .addCase(updateListedProject.fulfilled, (state, action) => {
                state.status = "succeeded"
            })
            .addCase(updateListedProjectBulk.pending, (state) => {
                state.status = "loading"
            })
            .addCase(updateListedProjectBulk.rejected, (state, action) => {
                state.status = "failed"
            })
            .addCase(updateListedProjectBulk.fulfilled, (state, action) => {
                state.status = "succeeded"
            })
            /**
             * Handles the case when a complete project object is updated
             */
            .addCase(updateProject.pending, (state) => {
                state.status = "loading"
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                state.status = "succeeded"
                state.current = action.payload
            })
            .addCase(updateProject.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.payload
            })

            /**
             * Changes post history update
             */
            .addCase("history/fetchResultsHistory/pending", (state) => {
                state.status = "loading"
            })
            .addCase("history/fetchResultsHistory/fulfilled", (state) => {
                state.status = "idle"
            })
            /**
             * Changes post results update
             */
            .addCase(
                "result/fetchUsecaseResults/fulfilled",
                (state, action) => {
                    state.status = "idle"
                    state.current.document.result.metaData =
                        action.payload.metaData
                },
            )
            .addCase(
                "microUsecase/updateCurrentMicroUsecase",
                (state, action) => {
                    const usecase = action.payload
                    if (Object.keys(state.current).length) {
                        state.current.document.usecase.current = [usecase?._id]
                    }
                },
            )
    },
})

export const selectAllProjects = (state) => state.project.collection
export const selectCurrentProject = (state) => state.project.current
export const getProjectStatus = (state) => state.project.status
export const getProjectError = (state) => state.project.error
export const getProjectType = (state) => state.project.projectType

export const {
    resetStateStaus,
    updateProjectFromCache,
    updateProjectType,
    clearCurrentProject,
} = projectSlice.actions

export default projectSlice.reducer
