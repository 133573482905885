import React from 'react'
import './LoaderScreen.css'

export default function LoaderScreen() {
  return (
    <div className='loading__screen__main__container' >
        <div className="loading__screen__main__container-box">
            <div class="loading__screen-box--animation-div"></div>
            <div className="loading__screen-box--text">Loading...</div>
        </div>
    </div>
  )
}