import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosHttp } from "content-studio/src/utils"
import { resultApi } from "content-studio/src/api"
import store from "content-studio/src/store/store"
const { REACT_APP_URI_PROJECT: URI_PROJECT } = process.env

const defaultState = {
    status: "idle", // "idle" | "loading" | "succeeded" | "failed"
    map: {},
    metaData: {},
    project: "",
    error: "",
}

const UpdateSliceMetaData = (state, action) => {
    const document = action?.payload?.document
    const metaData = document?.result?.metaData
    state.metaData = metaData
    // doubt why we are again change status
    // state.status = "idle"
}

export const fetchUsecaseResults = createAsyncThunk(
    "result/fetchUsecaseResults",
    async (config, thunkAPI) => {
        const { projectId, microUseCaseId } = config

        if (!projectId)
            throw new Error("Undefined 'project id' provided to patch result")

        if (!microUseCaseId)
            throw new Error(
                "Undefined 'microUseCaseId id' provided to patch result",
            )

        const res = await axiosHttp({
            axiosInstance: resultApi,
            method: "GET",
            url: `${URI_PROJECT}/${projectId}/suggestions?microUseCaseId=${microUseCaseId}`,
        })

        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)

        res.data.projectId = projectId
        res.data.microUseCaseId = microUseCaseId

        return res?.data
    },
)
export const fetchNewUseCaseResults = createAsyncThunk(
    "result/fetchNewUseCaseResults",
    async (config, thunkAPI) => {
        const { projectId, microUsecaseId } = config

        if (!projectId)
            throw new Error("Undefined 'project id' provided to patch result")

        if (!microUsecaseId)
            throw new Error(
                "Undefined 'microUseCaseId id' provided to patch result",
            )

        const res = await axiosHttp({
            axiosInstance: resultApi,
            method: "POST",
            url: `${URI_PROJECT}/${projectId}/suggestions`,
            requestConfig: config,
        })

        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)

        res.data.projectId = projectId
        res.data.microUseCaseId = microUsecaseId

        return res?.data
    },
)
export const updateResultProp = createAsyncThunk(
    "result/updateResultProp",
    async (config, thunkAPI) => {
        const { projectId, _id } = config

        if (!_id || !projectId)
            throw new Error("Undefined 'id' provided to patch result")

        const res = await axiosHttp({
            axiosInstance: resultApi,
            method: "PATCH",
            url: `${URI_PROJECT}/${projectId}/results/${_id}`,
            requestConfig: config,
        })

        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)

        return res?.data
    },
)

const resultsSlice = createSlice({
    name: "result",
    initialState: defaultState,
    reducers: {
        resetResultSlice: (state) => {
            state.metaData = {}
        },
        updateResultsStatus: (state, action) => {
            state.status = action.payload
        },
        updateResultsSMeta: (state, action) => {
            state.metaData = action.payload
        },
        updateResultsProject: (state, action) => {
            state.project = action.payload
        },
        updateResultMetaFromCache: (state, action) => {
            const { _id, document } = action.payload

            if (!document) return

            state.metaData = document?.result.metaData ?? {}
            if (!Object.keys(state.metaData).length) {
                // Fetch slice meta
                console.clear()
                console.warn(
                    "Need to fetch results metadata for project: ",
                    _id,
                )
                return
            }
        },

        updateResultCollectionFromCache: (state, action) => {},
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsecaseResults.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(fetchUsecaseResults.fulfilled, (state, action) => {
                const results = action.payload
                const { projectId } = results

                /**
                 * Updates the result collection
                 */
                state.map[projectId] = results.collection
                /**
                 * Updates the result meta of the project
                 */
                // state.status = "succeeded"
                state.status = "idle"
                state.metaData = results.metaData
                state.project = projectId
            })
            .addCase(fetchUsecaseResults.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.payload
            })
            .addCase(fetchNewUseCaseResults.pending, (state, action) => {
                state.status = "loading"
            
            })
            .addCase(fetchNewUseCaseResults.fulfilled, (state, action) => {
                const results = action.payload
                console.log(results,"results");
                const { projectId } = results

                /**
                 * Updates the result collection
                 */
                state.map[projectId] = results.collection
                /**
                 * Updates the result meta of the project
                 */
                // state.status = "succeeded"
                state.metaData = results.metaData
                state.project = projectId
                state.status = "idle"
            })
            .addCase(fetchNewUseCaseResults.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.payload
            
            })
            /**
             * For handling the case when user changes a particulr result property
             * like favoirte, delete etc.
             */
            .addCase(updateResultProp.pending, (state) => {
                state.status = "updating"
            })
            .addCase(updateResultProp.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.payload
            })
            .addCase(updateResultProp.fulfilled, (state, action) => {
                /**
                 * Before adding to current state we are cleaning results
                 * i.e. filtering out those results which have empty content
                 */
                const updatedResult = action?.payload
                const projectResults = state.map[state.project]
                const updatedResultIndex = projectResults?.findIndex(
                    (result) => result?._id === updatedResult?._id,
                )
                if (updatedResult?.isDeleted && updatedResultIndex > -1)
                    projectResults.splice(updatedResultIndex, 1)

                if(!updatedResult?.isDeleted)
                    projectResults[updatedResultIndex] = updatedResult

                state.map[state.project] = projectResults
                state.status = "succeeded"
                state.metaData = updatedResult.metaData
            })
            .addCase("project/clearCurrentProject", (state) => {
                const newState = {
                    ...state,
                    status: "idle",
                    metaData: {},
                    project: "",
                    error: "",
                }
            })
            /**
             * TODO: Sync loading states of project/actions with this
             * results slice status as well
             */
            .addCase("project/createNewProject/fulfilled", (state, action) => {
                UpdateSliceMetaData(state, action)
            })
            .addCase("project/createNewProject/pending", (state, action) => {
                state.status = "loading"
            })
            .addCase(
                "project/fetchSingleProject/fulfilled",
                (state, action) => {
                    UpdateSliceMetaData(state, action)
                },
            )
            .addCase(
                "project/fetchSingleProjectDocument/fulfilled",
                (state, action) => {
                    UpdateSliceMetaData(state, action)
                },
            )
            .addCase("project/updateProject/pending", (state) => {
                state.status = "loading"
            })
            .addCase("project/updateProject/fulfilled", (state, action) => {
                UpdateSliceMetaData(state, action)
            })
            .addCase("project/updateProjectProp/pending", (state) => {
                state.status = "idle"
            })
            .addCase("project/updateProjectProp/fulfilled", (state, action) => {
                UpdateSliceMetaData(state, action)
            })
    },
})

export const getResultsCollection = (projectId, usecaseId) => {
    const state = store.getState()

    if (!projectId) return []
    if (!state.result.map[projectId]) return []
    const filteredResults = state.result.map[projectId].filter((result)=>{
        return result.microUsecaseId === usecaseId && !result.isDeleted
    })
    return filteredResults
}
export const getResultsMeta = (state) => state.result.metaData
export const getResultsStatus = (state) => state.result.status
export const getResultsError = (state) => state.result.error
export const getResultsProject = (state) => state.result.project

export const {
    resetResultSlice,
    updateResultsStatus,
    updateResultMetaFromCache,
    updateResultCollectionFromCache,
    updateResultsProject,
    updateResultsSMeta,
} = resultsSlice.actions

export default resultsSlice.reducer
