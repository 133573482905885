import { Auth } from "aws-amplify"
const { REACT_APP_USER_AUTH_REDIRECT_URI: USER_AUTH_REDIRECT_URI } = process.env

export async function getAccessToken() {
    try {
        const currentSession = await Auth.currentSession()
        return currentSession.accessToken.jwtToken
    } catch (error) {
        window.location.href = USER_AUTH_REDIRECT_URI
    }
}
