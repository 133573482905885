import axios from "axios"

const axiosHttp = async (configuration) => {
    const { axiosInstance, method, url, requestConfig = {} } = configuration
    let payload = {...requestConfig}
    if(method === "DELETE"){
        payload = {}
        payload.data = {...requestConfig}
    }
    try {
        const res = await axiosInstance[method.toLowerCase()](url, payload)
        return res.data
    } catch (error) {
        return error.message
    }
}
export default axiosHttp
