import React, { useRef, useState, useEffect } from "react"
import { FieldGroup, FieldLabel, List, ListItem } from "@attrybtech/attryb-ui"
import "./select-input.sass"

const SelectInput = ({
    name,
    selectListData,
    selectDefaultValue,
    userInputHandler,
    selectInputRef
}) => {
    return (
        <div className="SelectInput" ref={selectInputRef}>
            <FieldGroup name="text-field">
                <FieldLabel name="text-field-label" forFieldValue={name}>
                    {name.split("-").join(" ")}
                </FieldLabel>
                <List
                    list={selectListData}
                    activeItem={selectDefaultValue}
                    selectCallback={(item) => {
                        const fakeEvent = {
                            target: {
                                name: name,
                                value: item.value,
                            },
                        }
                        userInputHandler(fakeEvent)
                    }}>
                    {selectListData.map((item) => {
                        return (
                            <ListItem key={item._id} data={item}>
                                {item?.value}
                            </ListItem>
                        )
                    })}
                </List>
            </FieldGroup>
        </div>
    )
}

export default SelectInput
