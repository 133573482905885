import { ACTION } from "./Store"

const Reducer = (state, action) => {
    switch (action.type) {
        case ACTION.UPDATE_SYNC_STATUS:
            return { ...state, syncing: action.payload }

        case ACTION.UPDATE_USECASES:
            return { ...state, usecases: action.payload }

        case ACTION.RESET_USECASES:
            return { ...state, usecases: [] }

        default:
            return state
    }
}

export default Reducer
