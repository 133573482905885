import { createSlice } from "@reduxjs/toolkit"
import { hash } from "content-studio/src/lib"

const initialState = {
    status: "idle", // "idle" | "loading" | "succeeded" | "failed"
    content: "",
    needSave: false,
    saveButtonClicked: false,
    editorCursor: {},
    movedContent: {
        counter: 0,
        content: "",
    },
    error: "",
}

const ContentEditorSlice = createSlice({
    name: "contentEditor",
    initialState,
    reducers: {
        mergeMovedContent: (state, action) => {
            state.movedContent = {
                counter: state.movedContent.counter + 1,
                type: action?.payload?.type,
                content: action?.payload?.content,
            }
        },
        clearMovedContent: (state) => {
            state.movedContent.content = ""
        },
        overwriteContent: (state, action) => {
            state.content = action.payload
        },
        updateEditorCursor: (state, action) => {
            state.editorCursor = action?.payload
        },
        setDocumentNeedSave: (state, action) => {
            state.needSave = action?.payload
        },
        setSaveButtonClicked: (state, action) => {
            state.saveButtonClicked = action?.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase("project/updateProject/fulfilled", (state, action) => {
                state.saveButtonClicked = false
                state.needSave = false
            })
            .addCase("project/updateProjectProp/fulfilled", (state, action) => {
                state.saveButtonClicked = false
                state.needSave = false
            })
    },
})

export const getContent = (state) => state.contentEditor.content
export const getMovedContent = (state) => state.contentEditor.movedContent
export const getContentEditorStatus = (state) => state.contentEditor.status
export const getContentEditorError = (state) => state.contentEditor.error
export const getEditorCursor = (state) => state.contentEditor.editorCursor
export const getDocumentNeedSave = (state) => state.contentEditor.needSave
export const getSaveButtonClicked = (state) =>
    state.contentEditor.saveButtonClicked

export const {
    mergeMovedContent,
    clearMovedContent,
    overwriteContent,
    updateEditorCursor,
    setDocumentNeedSave,
    setSaveButtonClicked,
} = ContentEditorSlice.actions

export default ContentEditorSlice.reducer
