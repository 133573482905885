import React from "react"
import "./badge.sass"

const Badge = ({ text, size = "md", type = "default" }) => {
    return (
        <div className="Badge" data-size={size} data-type={type}>
            <span>{text}</span>
        </div>
    )
}

export default Badge
