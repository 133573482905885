import React, { useState } from "react"
import { InputField, FieldGroup, FieldLabel } from "@attrybtech/attryb-ui"
import "./text-field-input.sass"

const TextFieldInput = ({
    name,
    label,
    charsLimit,
    placeholder,
    readOnly,
    value,
}) => {
    const [inputValue, setInputValue] = useState(value)

    return (
        <div className="TextFieldInputComp">
            <FieldGroup name="text-field">
                <FieldLabel
                    name="text-field-label"
                    forFieldValue={value}
                    maxCharsLimit={charsLimit}>
                    {label}
                </FieldLabel>
                <InputField
                    name={name}
                    maxCharsLimit={charsLimit}
                    placeholder={placeholder}
                    state={readOnly ? "disabled" : "default"}
                    preFilledValue={inputValue}
                    onChange={(event) => setInputValue(event?.target?.value)}
                />
            </FieldGroup>
        </div>
    )
}

export default TextFieldInput
