import React from "react"
import "./menu-item.sass"

const MenuItem = ({ item, state, clickCallback }) => {
    return (
        <div
            className={`MenuItem ${item?.isDisabled ? "Disabled" : ""}`}
            data-state={state}
            onClick={() => clickCallback(item)}>
            {item.icon ? (
                <div className="MenuItem__icon">{item.icon}</div>
            ) : null}
            <div className="MenuItem__text">{item.option}</div>
        </div>
    )
}

export default MenuItem
