import axios from "axios"
import { getAccessToken } from "../../../auth/utils"
import { ROUTES } from "../../../constants"
const { REACT_APP_BASE_URL: BASE_URL } = process.env

const projectApi = axios.create({
    baseURL: BASE_URL,
})

projectApi.interceptors.request.use(
    async (config) => {
        const token = await getAccessToken()
        config.headers = {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json",
            "Content-Type": "application/json",
        }

        return config
    },
    (error) => {
        Promise.reject(error)
    },
)

projectApi.interceptors.response.use(
	function (response) {
		return response
	},
	async function (error) {
		let res = error.response
		if (res.status === 403) {
            if(window.location.pathname !== `${ROUTES.unauthorized}`)
			    window.location.href = `${ROUTES.unauthorized}`
		}
		// send error logs
		return Promise.reject(error)
	}
)

export default projectApi
