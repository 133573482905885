import React, { useState, useEffect } from "react"
import ReactDOMServer from "react-dom/server"
import {
    FiHeart,
    FiCopy,
    FiTrash2,
    FiMoreVertical,
    FiArrowRight,
    FiFlag,
    FiPlus,
    FiMinus,
} from "react-icons/fi"
import { BiCheckDouble } from "react-icons/bi"
import { getAllMicroUsecases } from "content-studio/src/features/micro-usecase"
import { mergeMovedContent } from "content-studio/src/features/content-editor"
import { useDispatch, useSelector } from "react-redux"
import { Menu, Badge } from "content-studio/src/components"
import { hash } from "content-studio/src/lib"
import { updateResultProp } from "content-studio/src/features/results"
import { useSuggestionOperations } from "content-studio/src/hooks"
import "./result-card.sass"
import { selectCurrentProject } from "content-studio/src/features/project"
import { PROJECT_STATUS } from "content-studio/src/config"

const ResultCard = ({ result }) => {
    const dispatch = useDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const microUsecases = useSelector(getAllMicroUsecases)
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

    const {
        textCopied,
        setTextCopied,
        updateFavoriteStatus,
        copyToContentEditor,
        copyToClipboard,
        getFormattedContent,
        removeSuggestion,
        expandable,
        isExpanded,
        setIsExpanded,
    } = useSuggestionOperations(
        result,
        dispatch,
        updateResultProp,
        mergeMovedContent,
    )

    const contextMenuList = [
        {
            id: hash.getUIDPrefixed("smenu-"),
            option: "Flag Content",
            icon: <FiFlag />,
            isDisabled:true,
        },
        {
            id: hash.getUIDPrefixed("smenu-"),
            option: "Remove",
            icon: <FiTrash2 />,
        },
    ]

    const handlerContextMenu = (item) => {
        if(item.isDisabled) return;
        removeSuggestion()
        setIsContextMenuOpen(false)
    }

    const contextMenuTriggerJSX = (
        <div
            className="ButtonRound ButtonRound--plain"
            data-pressed={isContextMenuOpen}>
            <FiMoreVertical />
        </div>
    )

    useEffect(() => {
        if (!textCopied) return
        const interval = setInterval(() => {
            setTextCopied(false)
        }, 1000)

        return () => clearInterval(interval)
    }, [textCopied, setTextCopied])

    return (
        <div className="ResultCard">
            <div className="ResultCardHeader">
                {microUsecases && Object.keys(microUsecases)?.length ? (
                    <Badge
                        key={hash.getRandom()}
                        size="sm"
                        text={microUsecases[result?.microUsecaseId]?.name}
                    />
                ) : null}

                {result?.isUsed > 0 ? (
                    <div
                        className="Icon-IsUsed"
                        title="This suggestion was used in Text Editor">
                        <BiCheckDouble />
                    </div>
                ) : null}
            </div>

            <div
                className="ResultCardBody"
                data-expandable={expandable}
                data-expanded={expandable && isExpanded}>
                {getFormattedContent()}
                <div
                    data-expandable={expandable}
                    className="ResultCardExpander"
                    onClick={() => setIsExpanded((prev) => !prev)}>
                    {isExpanded ? (
                        <>
                            <FiMinus /> <span>Less</span>
                        </>
                    ) : (
                        <>
                            <FiPlus /> <span>More</span>
                        </>
                    )}
                </div>
            </div>
            <div className="ResultCardFooter">
                <div className="blank-column"></div>
                <div className="ResultCardActions">
                    <Menu
                        list={contextMenuList}
                        align="right"
                        isOpen={isContextMenuOpen}
                        triggerButton={contextMenuTriggerJSX}
                        triggerClickCallback={() =>
                            setIsContextMenuOpen((prev) => !prev)
                        }
                        itemClickCallback={handlerContextMenu}
                    />
                    {currentProject?.status === PROJECT_STATUS.ACTIVE ? (
                        <div
                            className="ButtonRound ButtonRound--plain"
                            title="Copy Text"
                            onClick={copyToClipboard}>
                            {textCopied ? (
                                <div className="CopyMessage font-small">
                                    Text copied to clipboard
                                </div>
                            ) : null}
                            <FiCopy />
                        </div>
                    ) : null}
                    <div
                        className="ButtonRound ButtonRound--plain"
                        title="Mark Favorite"
                        data-fav={result?.isFavourite ? "true" : "false"}
                        onClick={updateFavoriteStatus}>
                        <FiHeart />
                    </div>
                    {currentProject?.status === PROJECT_STATUS.ACTIVE ? (
                        <div
                            className="ButtonRound ButtonRound--outline"
                            title="Mark Favorite"
                            onClick={copyToContentEditor}>
                            <FiArrowRight />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default ResultCard
