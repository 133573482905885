/* eslint-disable react/prop-types */
import React, { useReducer, useMemo, createContext } from "react"
import Reducer from "./Reducer"

const initialState = {
    syncing: false,
    usecases: []
}

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState)

    const contextValue = useMemo(() => {
        return [ state, dispatch ]
    }, [state, dispatch])

    return (
        <StoreContext.Provider value={contextValue}>
            { children }
        </StoreContext.Provider>
    )
}

export const ACTION = {
    UPDATE_USECASES: "update_usecases",
    UPDATE_SYNC_STATUS: "update-sync-status",
    
    RESET_USECASES: "reset_usecases",    
}

export const StoreContext = createContext()
export default Store