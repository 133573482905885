import React from "react"
import "./blank-results-list.sass"
import { ReactComponent as Hamster } from "content-studio/src/assets/illustrations/hamster.svg"

const BlankResultsList = () => {
    return (
        <div className="BlankResultsList">
            <div className="BlankResultsList__content">
                <img
                    alt="icon"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAEGklEQVR4nO3cz2scZRgH8O8zTZtaaMVcSrtJEandldikJjkWL6IFUbwVPRRBjx4F21OtBbGX2D+gYmkK7aE38RTQi4h4KGrBQ/EQZTe2UYuQ7aaNmXkfD+mW6e77bhIy77w7O98PLOzMJPAw33ned34lABEREREREREREVEZSK+N965jLDG4COA1AHvzKekJBoI7ovjWGMzuP4VfAtTglTOAxesYG0rwM4CRHOvpJYHio/2n8HnoQrIUOTfEuKiKEVWgTz47FJi9cxXn8txBvjk74M85LCPMsLMhVXxSeXcwgnB2gCr29sFRb/0A+HjxCi7ktpc86hVAv39OL14ufghFDgAGOF2/XOyhyDkH/PElNM9CtumGGnzW2odfx0/iv9DFbIUzgN+/KFQA/agJ4BsjOPPc+7jt+iFnAAuXGEBG/k12YPLwe6jbNg65fku5+7PyTJRgFsBJ20ZnAIYBZEdxwrWJHZCPfa4NDCAwBhAY54DA2AGBuQMweZZRXhyCAuMQFBgDCIwBBMY5IDB2QGADHcCB56dxoDoDEeeDv9zoG1iAYg7mn09l/Mbjh0YDG8DB6gwOHpleX+iPi5pnAZxFNPKSKt4SWX/e4nwg8+OF4j6QqdSmUalOhS6jlzflhUtfAwPYAaPVKVSOHOuXo95BXgEweAGM1o6hUp0ANAldSm+KXe2vAxPAWG0So9UJAP185D8i+Kv9dSCuAw7VJjBaPdrnw06KylL7a+E74FDtKMaqLxZn5wMALAHorbevQfBOmIK2q0g7HwBg64BkAtrz7zUoK2IbghQNwIwHKahs9Km77a/pDrC+uUWZa8nk1VZ7IRVA3OAQlAPF3fRiKgBtoLh3H4pDdCm9mJ4D6sU6lSsoZweYuMHj3z9NnYIC6QD27KxjZTX3gsomcg1BUvuqaW6+uoweL5JSJhwdAABq6gB4LeCVaxIGAJgGGIBfErlOQwE1pi7CqdirZM3dAZEkjaLcBS2s4T09hiCjDbAD/FHcl8n5VnpV9xzAFvBpqXOF5SyIAXgjGwUQD9UxxIsxbzpuQwAdAcjx75vmhylejHmiHdcAgO2ZMC/GvIk2HIIAAHEDEAbgxSY6QFXrwonYE+kKoOu14QjayKeYElLpmoS739vWiAH4YrrngO4AInaANyubGIIQ2/+vDW3bfTlxq9W50tIBMQPwo+voBywByPHbTQDL3sspn80F8Ai7IGvSfRsCcAfAiThjqlvoAGUHZC6yXAWvr7eSRZ/FlJJGmw8gUvOb32pKKNIF62rrD5vheVU+GsuKqsbYtfs72zZrAPLyT3+vPlib9VtWeaw+jM/IzM0V27ae76M35w9/EImcH969cySK+Or6Vhhj9OGDtXtJnHz49OsLc6HrISIiIiIiIiIiIgrrf8dsioKEeE93AAAAAElFTkSuQmCC"
                />
                <div className="message">
                    <h4>Results</h4>
                    <p>
                        The suggestions you generate for chosen usecase will
                        appear here as results
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BlankResultsList
