import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    status: "idle", // "idle" | "loading" | "succeeded" | "failed"
    usecaseSidebarVisibility: true,
    metricSidebarVisibility: false,
    historySidebarVisibility: false,
    usecsaeSidebarTabs: {
        CONFIG: "configure",
        RESULTS: "results",
    },
    currentStep: "configure",
    error: "",
}

const UseCaseBlogSlice = createSlice({
    name: "usecaseBlog",
    initialState,
    reducers: {
        updateStep: (state, action) => {
            state.currentStep = action?.payload
        },
        resetStep: (state) => {
            state.currentStep = "configure"
        },
        showUsecaseSidebar: (state) => {
            state.usecaseSidebarVisibility = true
            state.historySidebarVisibility = false
        },
        hideUsecaseSidebar: (state) => {
            state.usecaseSidebarVisibility = false
        },
        showMetricSidebar: (state) => {
            state.metricSidebarVisibility = true
        },
        hideMetricSidebar: (state) => {
            state.metricSidebarVisibility = false
        },
        showHistorySidebar: (state) => {
            state.historySidebarVisibility = true
            state.usecaseSidebarVisibility = false
        },
        hideHistorySidebar: (state) => {
            state.historySidebarVisibility = false
        },
    },
})

export const getCurrentStep = (state) => {
    return state.usecaseBlog.currentStep
}

export const getUsecaseSidebarVisibility = (state) => {
    return state.usecaseBlog.usecaseSidebarVisibility
}

export const getMetricsSidebarVisibility = (state) => {
    return state.usecaseBlog.metricSidebarVisibility
}

export const getUsecaseSidebarTabs = (state) => {
    return state.usecaseBlog.usecsaeSidebarTabs
}

export const getUsecaseBlogStatus = (state) => {
    return state.usecaseBlog.status
}
export const getHistorySidebarVisibility = (state) => {
    return state.usecaseBlog.historySidebarVisibility
}
export const {
    updateStep,
    resetStep,
    showUsecaseSidebar,
    hideUsecaseSidebar,
    showMetricSidebar,
    hideMetricSidebar,
    showHistorySidebar,
    hideHistorySidebar,
} = UseCaseBlogSlice.actions

export default UseCaseBlogSlice.reducer
