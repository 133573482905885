const getToolbarIconPath = (name) => `/assets/icons/editor/${name}.svg`

export const mapContentFormatting = {
    "title": "header-one",
    "heading": "header-two",
    "single-bullet": "unordered-list-item",
    "list-bullet": "unordered-list-item",
    "list-number": "ordered-list-item",
}

const EDITOR = {
    SAVE_AFTER_SEC: 3,
    TOOLBAR: {
        options: [
            "history",
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "link",
            "image",
        ],
        inline: {
            options: ["bold", "italic", "underline"],
            bold: {
                icon: getToolbarIconPath("bold"),
                className: "toolbar-inline-bold",
            },
            italic: {
                icon: getToolbarIconPath("italic"),
                className: "toolbar-inline-italic",
            },
            underline: {
                icon: getToolbarIconPath("underline"),
                className: "toolbar-inline-underline",
            },
        },
        blockType: {
            inDropdown: true,
            options: ["Normal", "H1", "H2", "H3", "Blockquote", "Code"],
            className: "toolbar-block",
            component: undefined,
            dropdownClassName: "toolbar-block-dropdown",
        },
        fontSize: {
            icon: getToolbarIconPath("font-size"),
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
            className: "toolbar-fontsize",
            component: undefined,
            dropdownClassName: "toolbar-fontsize-dropdown",
        },
        list: {
            options: ["unordered", "ordered", "indent", "outdent"],
            unordered: {
                icon: getToolbarIconPath("list-ul"),
                className: "toolbar-list-ul",
            },
            ordered: {
                icon: getToolbarIconPath("list-ol"),
                className: "toolbar-list-ol",
            },
            indent: {
                icon: getToolbarIconPath("indent-left"),
                className: "toolbar-list-indentleft",
            },
            outdent: {
                icon: getToolbarIconPath("indent-right"),
                className: "toolbar-list-indentright",
            },
        },
        textAlign: {
            options: ["left", "center", "right", "justify"],
            left: {
                icon: getToolbarIconPath("text-left"),
                className: "toolbar-textalign-left",
            },
            center: {
                icon: getToolbarIconPath("text-center"),
                className: "toolbar-textalign-center",
            },
            right: {
                icon: getToolbarIconPath("text-right"),
                className: "toolbar-textalign-right",
            },
            justify: {
                icon: getToolbarIconPath("text-justify"),
                className: "toolbar-textalign-justify",
            },
        },
        link: {
            inDropdown: false,
            className: "toolbar-link",
            component: undefined,
            popupClassName: "toolbar-link-popup",
            dropdownClassName: "toolbar-link-dropdown",
            showOpenOptionOnHover: true,
            defaultTargetOption: "_self",
            options: ["link", "unlink"],
            link: {
                icon: getToolbarIconPath("link"),
                className: "toolbar-link-link",
            },
            unlink: {
                icon: getToolbarIconPath("unlink"),
                className: "toolbar-link-unlink",
            },
            linkCallback: undefined,
        },
        image: {
            icon: getToolbarIconPath("image"),
            className: "toolbar-image",
            component: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: undefined,
            previewImage: false,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
                height: "auto",
                width: "auto",
            },
        },
        history: {
            inDropdown: false,
            className: "toolbar-history",
            component: undefined,
            dropdownClassName: "toolbar-history-dropdown",
            options: ["undo", "redo"],
            undo: {
                icon: getToolbarIconPath("undo"),
                className: "toolbar-history-undo",
            },
            redo: {
                icon: getToolbarIconPath("redo"),
                className: "toolbar-history-redo",
            },
        },
    },
}

export default EDITOR
