export const getFirstUsecase = (usecases) => {
    let firstField = null

    for (const [key, value] of Object.entries(usecases)) {
        if (usecases[key]?.name === "Blog Title") firstField = value
    }
    return firstField
}

export const attachConfigurations = ({ usecases, configurations }) => {
    let copy = {}
    for (const [key, value] of Object.entries(usecases)) {
        copy[value._id] = { ...value }
        const configList = [...value?.configurations]
        configList?.forEach((configId, index) => {
            const config = configurations[configId]

            if (config.type !== "usecase") {
                copy[value._id].configurations[index] = config
            }
        })
    }
}
