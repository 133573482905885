import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { getProjectStatus } from "content-studio/src/features/project"
import {
    getResultstHistory,
    HistoryCard,
    EmptyHistoryList,
    BlankHistoryList,
} from "content-studio/src/features/history"
import { ReactComponent as Loader } from "content-studio/src/assets/animated/result-loader.svg"
import "./history-list.sass"

const HistoryList = ({ projectStatus, isBlankProject }) => {
    const historyList = useSelector(getResultstHistory)

    const getListState = () => {
        let state

        if (isBlankProject) state = "blank"
        if (!historyList?.length && projectStatus !== "loading") state = "blank"
        if (historyList?.length && projectStatus !== "loading")
            state = "filled-list"
        if (projectStatus === "loading") state = "loading"
        if (!historyList?.length && !isBlankProject) state = "no-history"

        return state
    }

    return (
        <div className="HistoryRenderer">
            <div className="HistoriesList">
                {getListState() === "blank" ? <BlankHistoryList /> : null}

                {getListState() === "no-history" ? <EmptyHistoryList /> : null}

                {getListState() === "filled-list"
                    ? historyList.map((history) => {
                          return history?.content ? (
                              <HistoryCard
                                  key={history?._id}
                                  history={history}
                              />
                          ) : null
                      })
                    : null}

                {getListState() === "loading" ? (
                    <div className="loading-state">
                        <Loader
                            style={{
                                width: "2rem",
                                height: "2rem",
                                margin: "0 auto",
                            }}
                        />
                        <small className="message">
                            Loading all history of results...
                        </small>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default HistoryList
