import {
    PROJECT_STATUS,
    PROJECT_TYPE,
} from "content-studio/src/config/project/project"
import { ENV } from "./global/global"
const {
    REACT_APP_PROJECT_POST_URL: PROJECT_POST_URL,
    REACT_APP_CONTENT_STUDIO_URL: CONTENT_STUDIO_URL,
} = process.env

export {
    PROJECT_POST_URL,
    CONTENT_STUDIO_URL,
    PROJECT_STATUS,
    PROJECT_TYPE,
    ENV,
}
