import React, { useEffect, useState, useCallback } from "react"
import {
    EditorState,
    Modifier,
    convertFromRaw,
    convertToRaw,
    ContentBlock,
    genKey,
    ContentState,
} from "draft-js"
import { useNavigate } from "react-router-dom"
import { getMovedContent } from "content-studio/src/features/content-editor"
import {
    updateProjectProp,
    getProjectType,
    clearCurrentProject,
} from "content-studio/src/features/project"
import { clearMovedContent } from "content-studio/src/features/content-editor"
import { useSelector, useDispatch } from "react-redux"
import { DraftJsEditor } from "content-studio/src/features/draftjs-editor"
import {
    getDocumentNeedSave,
    setDocumentNeedSave,
    getSaveButtonClicked,
    setSaveButtonClicked,
} from "content-studio/src/features/content-editor"
import {
    EDITOR,
    mapContentFormatting,
} from "content-studio/src/features/content-editor"
import "./content-editor.sass"
import { getCurrentMicroUsecase } from "content-studio/src/features/micro-usecase"
import { PROJECT_TYPE } from "content-studio/src/config"
import { getProjectStatus } from "../../../project"
import { useBeforeunloadPromt } from "content-studio/src/hooks"
import { resetResultSlice } from "content-studio/src/features/results"
import { resetStep } from "content-studio/src/features/use-case-blog"

const ContentEditor = ({ currentProject }) => {
    const [showBeforeunloadPromt, setShowBeforeunloadPromt] = useBeforeunloadPromt(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const movedContent = useSelector(getMovedContent)
    const saveBtnClicked = useSelector(getSaveButtonClicked)
    /**
     * TODO: Get usecase config here to set it with updated project prop
     * config. This prevents reverting back of usecase in config sidebar
     */
    const selectedUsecase = useSelector(getCurrentMicroUsecase)
    const projectType = useSelector(getProjectType)
    const defaultContent = currentProject?.document?.content
    const currentProjectId = currentProject?._id
    const documentNeedSave = useSelector(getDocumentNeedSave)
    const readOnlyMode = Object.keys(currentProject || {})?.length
        ? currentProject?.status !== "active"
        : false

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    )
    const projectStatus =  useSelector(getProjectStatus)
    const [userPrompt, setUserPrompt] = useState(false)

    const syncEditorState = (state) => {
        setEditorState(state)
        if (!currentProjectId) return
        return dispatch(setDocumentNeedSave(true))
    }

    const insertInlineText = useCallback((text, type = null, editorState) => {
        const currentContent = editorState?.getCurrentContent()
        const currentSelection = editorState?.getSelection()

        return Modifier?.replaceText(currentContent, currentSelection, text)
    }, [])

    const insertBlockText = useCallback((text, type, editorState) => {
        const contentState = editorState?.getCurrentContent()
        const currentSelection = editorState?.getSelection()

        const blockMap = ContentState?.createFromText("")?.getBlockMap()
        const newBlocks = text.split(/\n{1,}/g)?.map((item) => {
            const newBlock = new ContentBlock({
                key: genKey(),
                text: item ?? "",
                type: type,
            })

            return Array.from([newBlock.getKey(), newBlock])
        })

        const newBlockMap = blockMap.toSeq().concat(newBlocks).toOrderedMap()

        return Modifier?.replaceWithFragment(
            contentState,
            currentSelection,
            newBlockMap,
        )
    }, [])

    const insertText = useCallback(
        (text, type, editorState) => {
            if (!text) return false

            const format = mapContentFormatting[type]
            const isBlockType = Boolean(format)

            const insertion = isBlockType ? insertBlockText : insertInlineText

            const newContent = insertion(text, format, editorState)

            const newEditorState = EditorState.push(
                editorState,
                newContent,
                "insert-fragment",
            )

            return EditorState?.forceSelection(
                newEditorState,
                newContent?.getSelectionAfter(),
            )
        },
        [insertBlockText, insertInlineText],
    )

    const getPlainTextFrom = (state) => {
        if (!state) return null

        return state?.getCurrentContent()?.getPlainText("\u0001")
    }

    const getUserMovedText = useCallback(() => {
        const clone = { ...movedContent }
        /**
         * We give back the asked content and then reset the
         * state variable holding this content
         */
        dispatch(clearMovedContent())
        return clone
    }, [movedContent, dispatch])

    const updateEditor = useCallback(
        (text, type, editorState) => {
            const prevText = getPlainTextFrom(editorState)
            const updatedState = insertText(text, type, editorState)
            const newText = getPlainTextFrom(updatedState)
            const stateHasDiff = prevText !== newText
            /**
             * If content has difference, activate the save button
             */
            if (stateHasDiff) dispatch(setDocumentNeedSave(true))
            /**
             * Updating the content editor's state with suggestion moved by user
             */
            setEditorState(updatedState)
            /**
             * After updating the suggestion we are reseting the slice state object
             */
            dispatch(clearMovedContent())
        },
        [dispatch, insertText],
    )

    const saveProjectContent = useCallback(async() => {
        if (!currentProjectId) return
        /**
         * Reseting the click state of Save button
         */
        dispatch(setSaveButtonClicked(false))
        /**
         * Saving the present state of content editor
         * after converting the content into HTML string
         */
        const newConfig = { ...currentProject?.document?.usecase }
        newConfig.current = [selectedUsecase?._id]

        const payload = {
            _id: currentProjectId,
            name: currentProject?.name,
            content: JSON.stringify(
                convertToRaw(editorState.getCurrentContent()),
            ),
        }
       await dispatch(updateProjectProp(payload))
    }, [
        currentProjectId,
        editorState,
        dispatch,
        currentProject,
        selectedUsecase,
    ])

    const initEditorState = useCallback(() => {
        if (!defaultContent) return
        /**
         * Initializing the content editor with default content
         * as found in saved project's document.content
         */
        const content = convertFromRaw(JSON.parse(defaultContent))

        setEditorState(EditorState.createWithContent(content))
    }, [defaultContent])

    // const keyBindingFn = (e) => {
    //     if (
    //         e.keyCode === 83 /* `S` key */ &&
    //         KeyBindingUtil.hasCommandModifier(e)
    //     ) {
    //         return "ctrl_s_command"
    //     }

    //     // This wasn't the delete key, so we return Draft's default command for this key
    //     return Draft.getDefaultKeyBinding(e)
    // }

    // const handleKeyCommand = (command) => {
    //     if (command === "ctrl_s_command") {
    //         // Do what you want to here, then tell Draft that we've taken care of this command
    //         dispatch(setSaveButtonClicked(true))
    //         return "handled"
    //     }

    //     // This wasn't the 'delete-me' command, so we want Draft to handle it instead.
    //     // We do this by telling Draft we haven't handled it.
    //     return "not-handled"
    // }
    
    useEffect(()=>{
        if (documentNeedSave && projectType === PROJECT_TYPE.EXISTING && !window.onpopstate) {
            async function blockHistory(event){
                event.preventDefault()
                const userResponse = window.confirm(
                    "Save changes before going back?",
                )
                if(!userResponse){
                    // window.history.pushState(null, null,window.location.ref);
                    window.onpopstate = null
                    navigate("/content-studio/projects")
                    return false;
                }
                setUserPrompt(true)
            }
            window.history.pushState(null, null,window.location.href)
            window.onpopstate = function (event) {          
                    blockHistory(event)
            }
        };
    },[documentNeedSave, projectType])

    useEffect(()=>{
        if(projectStatus === "succeeded" && userPrompt){            
            dispatch(clearCurrentProject())
            dispatch(setDocumentNeedSave(false))
            dispatch(resetResultSlice())
            dispatch(resetStep())
            navigate("/content-studio/projects")
        }
    },[projectStatus, userPrompt])
    // } 
    
    useEffect(() => {
        return () => {
        if(projectType === PROJECT_TYPE.EXISTING) setShowBeforeunloadPromt(documentNeedSave)
        }
    }, [documentNeedSave,saveProjectContent])


    useEffect(()=>{
        if(userPrompt){
            saveProjectContent()
            window.onpopstate = null
        }
    },[userPrompt])

    useEffect(() => {
        /**
         * Whenever user moves the suggestion we are updating
         * content editor
         */
        const { content, type } = getUserMovedText()
        if (content) updateEditor(content, type, editorState)
    }, [movedContent, updateEditor, editorState, getUserMovedText])

    useEffect(() => {
        /**
         * Whenever save button is clicked in project header
         * we are saving the project
         */
        if (saveBtnClicked) saveProjectContent()
    }, [saveProjectContent, saveBtnClicked])

    useEffect(() => {
        /**
         * Initialize editor
         */
        initEditorState()
    }, [initEditorState])

    return (
         <div className="ContentEditor">
            <div className="ContentEditorCenterCol">
                <DraftJsEditor
                    state={editorState}
                    syncEditorState={syncEditorState}
                    toolbar={EDITOR.TOOLBAR}
                    readOnly={readOnlyMode}
                />
            </div>
        </div>
    )
}

export default ContentEditor
