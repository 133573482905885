/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useCallback, useReducer, useEffect } from "react"
import PropTypes from "prop-types"
import "./usecase-list.style.sass"
import { Button } from "@attrybtech/attryb-ui"
import { useClickOutside, useKeyPress } from "content-studio/src/hooks"
import { reducer, initialState, ACTION } from "../../reducers"
import { FiChevronDown } from "react-icons/fi"
import { UsecaseListItem } from "content-studio/src/features/micro-usecase"
import uniqid from "uniqid"

const UsecaseList = ({
    list,
    label,
    searchable,
    button,
    defaultItem,
    searchWhichKeys,
    onSelect,
    selectedConfigs,
}) => {
    /**
     * We are using default list as whole in filtered list here
     * but "useFilterListSearch" hook can be used in case the user
     * is allowed to search through the list.
     */
    const filteredList = list

    const [state, dispatch] = useReducer(reducer, initialState)
    const listRef = useRef(null)
    const escPress = useKeyPress("Escape")
    const downPress = useKeyPress("ArrowDown")
    const upPress = useKeyPress("ArrowUp")
    const enterPress = useKeyPress("Enter")

    const action = {
        hideList: () => dispatch({ type: ACTION.HIDE_LIST }),
        showList: () => dispatch({ type: ACTION.SHOW_LIST }),
        updateSelection: (item) =>
            dispatch({ type: ACTION.UPDATE_USER_SELECTION, payload: item }),
    }

    const closeList = useCallback(
        (event) => {
            if (event) return
            action.hideList()
        },
        [state.listVisibility],
    )

    useClickOutside(listRef, closeList)

    const onItemClicked = (item) => {
        action.hideList()
        action.updateSelection(item)
    }

    const onButtonClick = () =>
        state?.listVisibility ? action.hideList() : action.showList()

    const buttonProps = {
        variant: "subtle",
        onClick: onButtonClick,
        isPressed: state.listVisibility,
    }

    const renderSelectButton = () => {
        return (
            <Button {...buttonProps}>
                {state.userSelection?.name ?? button?.label} {<FiChevronDown />}
            </Button>
        )
    }

    const resetToDefaultList = () => {
        /**
         * Whenever, we open the list we want it to be the default list
         * not filtered one from the previously applied filter (if any)
         * Therefore, we will reset it to default whenever it is closed.
         * Which is equivalent of setting the user query to null
         */
        if (state.listVisibility) return
        dispatch({ type: ACTION.RESET_SEARCH_QUERY })
    }

    const scrollElIntoView = (index) => {
        /**
         * A side effect. From the list of .ListItem nodes, scroll the node
         * into which have index same as the state's cursor index
         */
        const classListNodes = document.getElementsByClassName("ListItem")

        classListNodes[index]?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        })
    }

    const scrollSelectedElementIntoView = () => {
        if (state?.userSelection && !Object.keys(state?.userSelection)?.length)
            return
        /**
         * We are setting the cursor to user selected item's index in the list
         * So that when list opens the selected item gets focused upon
         * automatically
         */
        const indexOfUserSelection =
            Object.keys(state.list)?.indexOf(state.userSelection?._id) ?? 0
        dispatch({ type: ACTION.UPDATE_CURSOR, payload: indexOfUserSelection })

        scrollElIntoView(indexOfUserSelection)
    }

    useEffect(() => {
        /**
         * Whenever the default item provided changes, we update
         * the userSelection state with default selection
         */
        action.updateSelection(defaultItem)
    }, [defaultItem])

    useEffect(() => {
        /**
         * Initially we fill the dropdown list with the items in list prop and
         * again do the same whenever this provided list changes
         */
        dispatch({ type: ACTION.UPDATE_LIST, payload: list })
    }, [list])

    useEffect(() => {
        /**
         * We update the ref list state whenever the reference to the list of
         * this component changes
         */
        dispatch({ type: ACTION.UPDATE_LIST_REF, payload: listRef })
    }, [listRef])

    useEffect(() => {
        /**
         * Resets to the default list
         */
        resetToDefaultList()
        /**
         * Brings selected item into view if any
         */
        scrollSelectedElementIntoView()
    }, [state.listVisibility])

    useEffect(() => {
        if (
            state.userSelection &&
            !Object.keys(state.userSelection ?? {})?.length
        )
            return
        /**
         * Whenever user selects an item we call onSelect callback and
         * pass this user selected item as an argument of it
         */
        onSelect(state.userSelection)
        dispatch({
            type: ACTION.UPDATE_USER_SELECTION,
            payload: state.userSelection,
        })
    }, [state.userSelection])

    useEffect(() => {
        /**
         * Whenever custom hook filtered list changes we'll sync
         * the resultant list with component's state
         */
        dispatch({ type: ACTION.UPDATE_LIST, payload: filteredList })
    }, [filteredList])

    useEffect(() => {
        /**
         * If Esc is pressed hide the list else do nothing
         */
        if (!escPress) return
        action.hideList()
    }, [escPress])

    useEffect(() => {
        const listLength = Object.keys(state.list)?.length
        if (listLength && upPress && state.cursor > 0 && state.listVisibility)
            dispatch({ type: ACTION.DECREMENT_CURSOR })
    }, [upPress])

    useEffect(() => {
        const listLength = Object.keys(state.list)?.length
        if (
            Object.keys(state.list)?.length &&
            downPress &&
            state.cursor < listLength - 1 &&
            state.listVisibility
        )
            dispatch({ type: ACTION.INCREMENT_CURSOR })
    }, [downPress])

    useEffect(() => {
        const listLength = Object.keys(state.list)?.length
        if (!listLength || !state.listVisibility) return

        scrollElIntoView(state.cursor)

        if (enterPress) {
            const currentItem = Object.keys(state.list)[state.cursor]
            onItemClicked(state.list[currentItem])
        }
    }, [state.cursor, enterPress])

    return (
        <div className="UsecaseList" ref={listRef}>
            <div className="list-select" title={state.userSelection?.label}>
                {label ? <div className="TextFieldLabel">{label}</div> : null}
                {renderSelectButton()}
            </div>

            {state.listVisibility ? (
                <div className="list-container" data-haslabel={Boolean(label)}>
                    <div className="list-body">
                        {Object.keys(state.list)?.length
                            ? Object.keys(state.list)?.map((key, index) => {
                                  if (!state.list[key]?.active) return null

                                  return (
                                      <UsecaseListItem
                                          key={state.list[key]._id}
                                          item={state.list[key]}
                                          /**
                                           * TODO: Fix this isActive logic
                                           */
                                          isActive={
                                              state.userSelection?._id ===
                                              state.list[key]?._id
                                          }
                                          hasCursor={index === state.cursor}
                                          onClickCallback={onItemClicked}
                                          hasUsed={selectedConfigs?.includes(
                                              state.list[key]._id,
                                          )}
                                      />
                                  )
                              })
                            : null}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

UsecaseList.propTypes = {
    list: PropTypes.object.isRequired,
    label: PropTypes.string,
    searchable: PropTypes.bool,
    button: PropTypes.object.isRequired,
    defaultItem: PropTypes.object,
    searchWhichKeys: PropTypes.array,
    onSelect: PropTypes.func,
    selectedConfigs: PropTypes.array,
}

UsecaseList.defaultProps = {
    list: [
        {
            id: uniqid(),
            option: "Anything",
        },
    ],
    label: "Choose usecase",
    searchable: false,
    button: {
        type: "secondary",
        icon: null,
        label: "Button",
    },
    defaultItem: null,
    searchWhichKeys: ["option"],
    selectedConfigs: [],
    onSelect: () => {},
}

export default UsecaseList
