import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosHttp } from "content-studio/src/utils"
import { useCasesApi } from "content-studio/src/api"
const { REACT_APP_URI_PROJECT: URI_PROJECT } = process.env

const defaultState = {
    status: "idle", // "idle" | "loading" | "succeeded" | "failed"
    results: [],
    projectId: {},
    error: "",
}

export const fetchResultsHistory = createAsyncThunk(
    "history/fetchResultsHistory",
    async (_id, thunkAPI) => {
        if (!_id)
            throw new Error("Undefined project id provided to fetch history")

        const res = await axiosHttp({
            axiosInstance: useCasesApi,
            method: "GET",
            url: `${URI_PROJECT}/${_id}/history`,
        })

        if (typeof res?.data !== "object") return thunkAPI.rejectWithValue(res)

        return res?.data
    },
)

const historySlice = createSlice({
    name: "history",
    initialState: defaultState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchResultsHistory.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(fetchResultsHistory.fulfilled, (state, action) => {
                state.status = "succeeded"
                const resultsHistory = action?.payload
                const cleanedResultsHistory = resultsHistory?.filter(
                    (history) => history?.content !== "",
                )
                state.results = [...cleanedResultsHistory]
                state.fetchCount += 1
            })
            .addCase(fetchResultsHistory.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.payload?.message
            })
            .addCase("project/clearCurrentProject", (state) => {
                // cache.setHistorySlice(defaultState)
            })
            .addCase("result/updateResultProp/fulfilled", (state, action) => {
                const result = action.payload
                const { _id, projectId } = result

                /**
                 * Updates the result collection
                 */
                const localResultIndex = state.results?.findIndex(
                    (item) => item._id === _id,
                )

                if (localResultIndex < 0)
                    state.results = state.results?.concat(result)
                else
                    state.results[localResultIndex] = {
                        ...state.results[localResultIndex],
                        ...result,
                    }
                /**
                 * Updates the result meta of the project
                 */
                state.status = "succeeded"
                state.projectId = projectId
            })
    },
})

export const getResultstHistory = (state) => state.history.results

export default historySlice.reducer
