import PropTypes from "prop-types"
import React, { useState, useRef, useEffect, useCallback} from "react"
import { useNavigate } from "react-router-dom"
import {
    FiChevronLeft,
    FiSettings,
    FiArchive,
    FiInbox,
    FiTrash2,
} from "react-icons/fi"
import { PROJECT_STATUS } from "content-studio/src/config/index"
import { useDispatch, useSelector } from "react-redux"
import { Badge, Menu } from "content-studio/src/components"
import { hash } from "content-studio/src/lib"
import { useClickOutside } from "content-studio/src/hooks"
import {
    setSaveButtonClicked,
    getDocumentNeedSave,
} from "content-studio/src/features/content-editor"
import {
    selectCurrentProject,
    getProjectStatus,
    updateProjectProp,
} from "content-studio/src/features/project"
import { Button } from "@attrybtech/attryb-ui"
import "./project-header.sass"

const ProjectHeader = ({ parentRef }) => {
    const dispatch = useDispatch()
    const documentNeedSave = useSelector(getDocumentNeedSave)
    const inputRef = useRef(null)
    const currentProject = useSelector(selectCurrentProject)
    const navigate = useNavigate();
    const projectSliceStatus = useSelector(getProjectStatus)

    const [isSettingsOpen, setIsSettingsOpen] = useState(false)
    const [projectNameEditable, setProjectNameEditable] = useState(false)
    const [badgeUID, setBadgeUID] = useState(hash.getUID())
    const [projectName,setProjectName]= useState(currentProject?.name ??`Untitled Draft ${hash.getRandom()?.slice(1, 4)}`)
    
    useEffect(()=>{
       if(currentProject?.name) setProjectName(currentProject?.name)
    },[currentProject?.name])

    const projectSettingsList = [
        {
            id: hash.getUIDPrefixed("smenu-"),
            option: "Move to Inbox",
            icon: <FiInbox />,
            name: "active",
        },
        {
            id: hash.getUIDPrefixed("smenu-"),
            option: "Archive Project",
            icon: <FiArchive />,
            name: "archive",
        },
        {
            id: hash.getUIDPrefixed("smenu-"),
            option: "Move to Trash",
            icon: <FiTrash2 />,
            name: "trash",
        },
    ]

    const generateProjectsSettingDropdown = () => {
        let list = [...projectSettingsList]

        if (currentProject?.status === "active") list?.splice(0, 1)
        if (currentProject?.status === "archive") list?.splice(1, 1)
        if (currentProject?.status === "trash") list?.splice(2, 1)

        return list
    }

    const renameProject = useCallback(() => {
        exitProjectRename()
        if (!Object.keys(currentProject)?.length) return
        const updatedName = inputRef?.current?.textContent
        if(projectName === updatedName) return;
        const config = {
            _id: currentProject?._id,
            name: updatedName
        }

        dispatch(updateProjectProp(config))
    }, [currentProject, dispatch])

    const exitProjectRename = () => {
        setProjectNameEditable(false)
        inputRef?.current?.blur()
    }

    const useClickOutsideCallback = useCallback(
        (event) => {
            if (!Object.keys(event ?? {})?.length && projectNameEditable)
                renameProject()
        },
        [renameProject, projectNameEditable],
    )

    useClickOutside(inputRef, useClickOutsideCallback)

    const handlerSettingsMenu = (item) => {
        const optionStatusMap = {
            "Archive Project": "archive",
            "Move to Trash": "trash",
            "Move to Inbox": "active",
        }
        const config = {
            _id: currentProject?._id,
            status: optionStatusMap[item.option],
        }
        setIsSettingsOpen(false)

        if (!currentProject?._id) return
        dispatch(updateProjectProp(config))
    }

    const triggerButtonJSX = (
        <div className="ButtonRound" data-pressed={isSettingsOpen}>
            <FiSettings />
        </div>
    )

    const getButtonSate = () => {
        let state = "default"

        if (projectSliceStatus === "patch-loading") state = "loading"
        if (!documentNeedSave) state = "disabled"

        return state
    }

    useEffect(() => {
        setBadgeUID(hash.getUID())
    }, [currentProject])

    return (
        <div className="ProjectHeader" ref={parentRef}>
            <div className="ProjectHeader__left-actions">
            </div>
            <div className="ProjectHeader__content">
                <div className="lhs">
                    <div
                        ref={inputRef}
                        className="ProjectName"
                        contentEditable="true"
                        onClick={() => setProjectNameEditable(true)}
                        onKeyDown={(event) => {
                            if (event?.key !== "Enter") return
                            event?.preventDefault()
                            renameProject()
                        }}>
                        {projectName}
                    </div>
                    {!currentProject?._id ? (
                        <Badge
                            key={badgeUID}
                            size="sm"
                            type="danger"
                            text="Unsaved Project"
                        />
                    ) : (
                        <Badge
                            key={badgeUID}
                            size="sm"
                            type="success"
                            text="Saved!"
                        />
                    )}
                </div>

                {/* {mapStatusActions[ProjectStatus ?? "Inbox"]} */}

                <div className="rhs">
                    {currentProject?._id &&
                    currentProject?.status === PROJECT_STATUS.ACTIVE ? (
                        <div className="ButtonSave">
                            <Button
                                loadingIcon="/assets/images/loader.svg"
                                isPressed={!documentNeedSave}
                                state={getButtonSate()}
                                onClick={() => {
                                    if (!documentNeedSave) return
                                    dispatch(setSaveButtonClicked(true))
                                }}>
                                {documentNeedSave ? "Save" : "Saved"}
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="ProjectHeader__right-actions">
                {currentProject?._id ? (
                    <Menu
                        list={generateProjectsSettingDropdown()}
                        isOpen={isSettingsOpen}
                        align="right"
                        triggerButton={triggerButtonJSX}
                        triggerClickCallback={() =>
                            setIsSettingsOpen((prev) => !prev)
                        }
                        itemClickCallback={handlerSettingsMenu}
                    />
                ) : null}
            </div>
        </div>
    )
}

ProjectHeader.propTypes = {
    parentRef: PropTypes.object,
    goBack: PropTypes.func,
    projectName: PropTypes.string,
    ProjectStatus: PropTypes.oneOf(Object.values(PROJECT_STATUS)),
}

export default ProjectHeader
