import React, { useState, useRef } from "react"
import {
    TextFieldInput,
    RadioInput,
    SelectInput,
} from "content-studio/src/features/inputs"
import "./configuration-form.sass"

const ConfigurationForm = ({ usecase, projectReadOnly, updateCallback,radioInputRef,selectInputRef }) => {
    const formRef = useRef(null)
    const [values, setValues] = useState({})

    const prepareValues = (name, value) => {
        const temp = { ...values }
        temp[usecase._id] = { ...temp[usecase._id] }
        temp[usecase._id][name] = value
        setValues(temp)
        updateCallback(temp)
    }

    const userInputHandler = (event) => {
        const targetName = event.target.name
        const targetValue = event.target.value

        prepareValues(targetName, targetValue)
    }

    return (
        <div className="ConfigurationForm" ref={formRef}>
            {usecase?.configurations?.map((config) => {
                const { _id, name, type, defaultValue, value } = config
                let selectListData, selectDefaultValue

                if (defaultValue?.length) {
                    selectListData = defaultValue?.map(
                        (defaultValueItem, index) => ({
                            _id: index,
                            value: defaultValueItem,
                            data: { _id: index, value: defaultValueItem },
                        }),
                    )

                    selectDefaultValue = selectListData?.find(
                        (item) => item.value === value,
                    )
                }

                return (
                    <div
                        key={_id}
                        className="input-group"
                        data-config={_id}
                        onKeyUp={userInputHandler}>
                        {type === "input" ? (
                            <TextFieldInput
                                /**
                                 * We want to reset value of input controls on each use case change
                                 * therefore, changing the key
                                 */
                                key={config._id}
                                value={config.defaultValue}
                                readOnly={projectReadOnly}
                                {...config}
                            />
                        ) : null}

                        {type === "radio" ? (
                            <RadioInput
                                {...config}
                                key={config._id}
                                readOnly={projectReadOnly}
                                onClick={userInputHandler}
                                radioInputRef={radioInputRef}
                            />
                        ) : null}

                        {type === "select" ? (
                            <SelectInput
                                name={name}
                                selectListData={selectListData}
                                selectDefaultValue={selectDefaultValue}
                                userInputHandler={userInputHandler}
                                selectInputRef={selectInputRef}
                            />
                        ) : null}
                    </div>
                )
            })}
        </div>
    )
}

export default ConfigurationForm
