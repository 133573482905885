import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosHttp } from "content-studio/src/utils"
import { useCasesApi } from "content-studio/src/api"
import { getFirstUsecase } from "./slice-utils"
const { REACT_APP_URI_USECASE: URI_USECASE } = process.env

const defaultState = {
    status: "idle", // "idle" | "loading" | "succeeded" | "failed"
    microUsecases: {},
    configurations: {},
    current: {},
    error: "",
}

export const fetchMicroUsecases = createAsyncThunk(
    "microUsecase/fetchMicroUsecases",
    async () => {
        return axiosHttp({
            axiosInstance: useCasesApi,
            method: "GET",
            url: URI_USECASE,
        })
    },
)

const updateSliceCurrentUsecase = (state, action) => {
    const document = action?.payload?.document
    const activeUsecaseId = document?.usecase?.current?.length
        ? document?.usecase?.current[0]
        : []
    state.current = state.microUsecases[activeUsecaseId]
}

const microUsecaseSlice = createSlice({
    name: "microUsecase",
    initialState: defaultState,
    reducers: {
        updateCurrentMicroUsecase: (state, action) => {
            state.current = action.payload
        },

        resetMicrousecases: (state) => {
            state = {
                ...defaultState,
                microUsecases: state.microUsecases,
                configurations: state.configurations,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMicroUsecases.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(fetchMicroUsecases.fulfilled, (state, action) => {
                state.status = "succeeded"
                const payloadData = action.payload?.data
                state.microUsecases = payloadData?.usecases || {}
                state.configurations = payloadData?.configurations || {}
            })
            .addCase(fetchMicroUsecases.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.payload?.message
            })
            .addCase("project/clearCurrentProject", (state) => {
                state = {
                    ...defaultState,
                    microUsecases: state.microUsecases,
                    configurations: state.configurations,
                }
            })
            .addCase("project/createNewProject/fulfilled", (state, action) => {
                updateSliceCurrentUsecase(state, action)
            })
            .addCase(
                "project/fetchSingleProject/fulfilled",
                (state, action) => {
                    updateSliceCurrentUsecase(state, action)
                },
            )
            .addCase(
                "project/fetchSingleProjectDocument/fulfilled",
                (state, action) => {
                    updateSliceCurrentUsecase(state, action)
                },
            )
            .addCase("project/updateProject/fulfilled", (state, action) => {
                updateSliceCurrentUsecase(state, action)
            })
            .addCase("project/updateProjectProp/fulfilled", (state, action) => {
                updateSliceCurrentUsecase(state, action)
            })
    },
})
 
export const getMicroUsecaseStatus = (state) => state.microUsecase.status
export const getMicroUsecaseError = (state) => state.microUsecase.error
export const getCurrentMicroUsecase = (state) => {
    if (Object.keys(state.microUsecase?.current ?? {})?.length)
        return state.microUsecase.current

    return getFirstUsecase(state.microUsecase.microUsecases)
}
export const getAllMicroUsecases = (state) => {
    console.log(state);
    return state.microUsecase?.microUsecases
}
export const getAllConfigurations = (state) =>
    state.microUsecase?.configurations

export const { updateCurrentMicroUsecase, resetMicrousecases } =
    microUsecaseSlice.actions

export default microUsecaseSlice.reducer
