import React from "react"
import "./empty-editor-metrics-sidebar.sass"
import { ReactComponent as Hamster } from "content-studio/src/assets/illustrations/hamster.svg"
const EmptyEditorMetricsSidebar = () => {
    return (
        <div className="EmptyEditorMetricsSidebar">
            <div className="EmptyEditorMetricsSidebar__content">
                <div className="message">
                    <h4>Metrics coming soon</h4>
                    <small>
                        Our hamster is working hard to bring you soon the live
                        metrics for your content.
                    </small>
                </div>
                <Hamster />
            </div>
        </div>
    )
}

export default EmptyEditorMetricsSidebar
