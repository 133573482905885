import React from 'react';
import "./orgAvatar.css";
const OrgAvatar = ({name="",onAvatarClick,...props}) => {

    const avatarColorArray = [
        {letter:"a", color:"#5080E9"},
        {letter:"b", color:"#84CEEB"},
        {letter:"c", color:"#5AB9EA"},
        {letter:"d", color:"#C1C8E4"},
        {letter:"e", color:"#8860D0"},
        {letter:"f", color:"#5080E9"},
        {letter:"g", color:"#84CEEB"},
        {letter:"h", color:"#5AB9EA"},
        {letter:"i", color:"#C1C8E4"},
        {letter:"j", color:"#8860D0"},
        {letter:"k", color:"#5080E9"},
        {letter:"l", color:"#84CEEB"},
        {letter:"m", color:"#5AB9EA"},
        {letter:"n", color:"#C1C8E4"},
        {letter:"o", color:"#8860D0"},
        {letter:"p", color:"#5080E9"},
        {letter:"q", color:"#84CEEB"},
        {letter:"r", color:"#5AB9EA"},
        {letter:"s", color:"#C1C8E4"},
        {letter:"t", color:"#8860D0"},
        {letter:"u", color:"#5080E9"},
        {letter:"v", color:"#84CEEB"},
        {letter:"w", color:"#5AB9EA"},
        {letter:"x", color:"#C1C8E4"},
        {letter:"y", color:"#8860D0"},
        {letter:"z", color:"#5080E9"},
    ]

 
    if(!name) return null;

    let backgroundColor = avatarColorArray.find(avatar=> {

       return avatar.letter.toUpperCase()===name.charAt(0).toUpperCase()
    }).color;

    let nameArr = name.split(" ");
    return (
        <div className={`orgAvatar ${props.className ? props.className:""}`} style={{backgroundColor:backgroundColor}} onClick={onAvatarClick}>
            {nameArr.length===1 ? (
                <React.Fragment>
                    {nameArr[0].charAt(0).toUpperCase()}

                </React.Fragment>
            ): (
                <React.Fragment>
                    {nameArr[0].charAt(0).toUpperCase() +  nameArr[1].charAt(0).toUpperCase()}

                </React.Fragment>

            )}
        </div>
    );
};

export default OrgAvatar;