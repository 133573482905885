/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import PropTypes from "prop-types"
import UseCaseSidebarBlog from "../use-case-blog-sidebar/use-case-blog-sidebar"
import { ContentEditor } from "content-studio/src/features/content-editor"
import { FiSidebar, FiBarChart2, FiClock } from "react-icons/fi"
import "./use-case-blog.sass"
import { useSelector, useDispatch } from "react-redux"
import {
    getCurrentStep,
    getUsecaseSidebarTabs,
    getUsecaseSidebarVisibility,
    updateStep,
    hideUsecaseSidebar,
    showUsecaseSidebar,
    hideMetricSidebar,
    showMetricSidebar,
    getMetricsSidebarVisibility,
    hideHistorySidebar,
    showHistorySidebar,
    getHistorySidebarVisibility,
} from "content-studio/src/features/use-case-blog"
import { EditorMetricsSidebar } from "content-studio/src/features/editor-metrics-sidebar"
import { useEffect } from "react"

import {
    getResultsCollection,
    getResultsMeta,
    getResultsProject,
} from "content-studio/src/features/results"
import { HistorySidebar } from "content-studio/src/features/history"
import { getCurrentMicroUsecase } from "content-studio/src/features/micro-usecase"

const UseCaseBlog = ({
    projectType,
    projectStatus,
    currentProject,
    generateSuggestions,
    fetchResults,
}) => {
    const dispatch = useDispatch()
    const currentSidebarTab = useSelector(getCurrentStep)
    const currentMicroUsecase = useSelector(getCurrentMicroUsecase)
    const metaDataCount = useSelector(getResultsMeta)
    const usecaseSidebarVisibility = useSelector(getUsecaseSidebarVisibility)
    const historySidebarVisibility = useSelector(getHistorySidebarVisibility)
    const USECASE_SIDEBAR_TABS = useSelector(getUsecaseSidebarTabs)
    const metricSidebarVisibility = useSelector(getMetricsSidebarVisibility)
    const projectId = currentProject?._id
    const currentMicroUsecaseId = currentMicroUsecase?._id
    const results = useSelector(() =>
        getResultsCollection(projectId, currentMicroUsecaseId),
    )
    // const resultsProjectId = useSelector(getResultsProject)

    const canFetchResults = () => {
        /**
         * If metaData has results count > 0 for the selected usecase
         * and the caches results for the selected usecase !== result count in metaData
         * then we need to fetch results for this selected usecase
         */
        const projectExist = !!Object.keys(currentProject ?? {}).length
        const isProjectActive = currentProject?.status === "active"
        const usecaseMetaDataCount = projectExist
            ? metaDataCount[currentMicroUsecaseId]
            : 0
        const resultsCount = projectExist ? results?.length : 0
        const resultsMetaHasDiff = usecaseMetaDataCount !== resultsCount

        return projectExist && isProjectActive && resultsMetaHasDiff
    }
    //By default results fetch API will call, only on generate button we are not calling it.
    const switchTabHandler = (stepName,fetchResultsCall=true) => {
        if (
            currentSidebarTab !== stepName &&
            stepName === "results" &&
            canFetchResults()
        ) {
            if(fetchResultsCall) fetchResults(currentProject?.document?.usecase)
        }
        dispatch(updateStep(stepName))
    }

    const blogSidebarProps = {
        generateSuggestions: generateSuggestions,
        currentStep: currentSidebarTab,
        switchTabTo: switchTabHandler,
        USECASE_SIDEBAR_TABS: USECASE_SIDEBAR_TABS,
        currentProject: currentProject,
        projectStatus: projectStatus,
    }

    const historySidebarProps = {
        currentStep: currentSidebarTab,
        currentProject: currentProject,
        projectStatus: projectStatus,
    }

    const metricsSidebarProps = {
        generateSuggestions: generateSuggestions,
        currentStep: currentSidebarTab,
        switchTabTo: () => null,
        USECASE_SIDEBAR_TABS: USECASE_SIDEBAR_TABS,
        currentProject: currentProject,
    }

    const getSidebarLayoutName = () => {
        if (usecaseSidebarVisibility && metricSidebarVisibility) return "both"
        if (usecaseSidebarVisibility) return "left"
        if (historySidebarVisibility) return "left"
        if (metricSidebarVisibility) return "right"
    }

    useEffect(() => {
        dispatch(showUsecaseSidebar())
    }, [])

    return (
        <div className="UseCaseBlog" data-withsidebars={getSidebarLayoutName()}>
            <div className="UseCaseBlog-left-col">
                <div
                    className="ButtonRound ButtonRound--plain"
                    title="Configure Content"
                    data-pressed={usecaseSidebarVisibility}
                    onClick={() => {
                        usecaseSidebarVisibility
                            ? dispatch(hideUsecaseSidebar())
                            : dispatch(showUsecaseSidebar())
                    }}>
                    <FiSidebar />
                </div>
                <div
                    className="ButtonRound ButtonRound--plain"
                    title="Configure Content"
                    data-pressed={historySidebarVisibility}
                    onClick={() => {
                        historySidebarVisibility
                            ? dispatch(hideHistorySidebar())
                            : dispatch(showHistorySidebar())
                    }}>
                    <FiClock />
                </div>
            </div>
            {usecaseSidebarVisibility ? (
                <UseCaseSidebarBlog {...blogSidebarProps} />
            ) : null}

            {historySidebarVisibility ? (
                <HistorySidebar {...historySidebarProps} />
            ) : null}

            <div className="UseCaseBlog-middle-col">
                <ContentEditor currentProject={currentProject} />
            </div>

            {metricSidebarVisibility ? (
                <EditorMetricsSidebar {...metricsSidebarProps} />
            ) : null}
            <div className="UseCaseBlog-right-col">
                <div
                    className="ButtonRound ButtonRound--plain"
                    data-pressed={metricSidebarVisibility}
                    title="Content Metrics"
                    onClick={() =>
                        metricSidebarVisibility
                            ? dispatch(hideMetricSidebar())
                            : dispatch(showMetricSidebar())
                    }>
                    <FiBarChart2 />
                </div>
            </div>
        </div>
    )
}

UseCaseBlog.propTypes = {
    generateSuggestions: PropTypes.func,
}

export default UseCaseBlog
