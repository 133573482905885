import React, { useEffect, useRef } from "react"
import { MenuItem } from "content-studio/src/components"
import "./menu.sass"

const Menu = ({
    list,
    isOpen,
    align,
    triggerButton,
    triggerClickCallback,
    itemClickCallback,
}) => {
    const menuRef = useRef(null)

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
        if(isOpen) triggerClickCallback()
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      });

    return (
        <div className="Menu" ref={menuRef} data-align={align}>
            <div className="menu-select" onClick={triggerClickCallback}>
                {triggerButton}
            </div>

            {isOpen ? (
                <div className="menu-container">
                    <div className="menu-body">
                        {list?.length
                            ? list.map((item) => (
                                  <MenuItem
                                      key={item.id}
                                      item={item}
                                      state="default"
                                      clickCallback={itemClickCallback}
                                  />
                              ))
                            : null}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default Menu
