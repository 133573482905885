import React, {
    useState,
    useRef,
    useMemo,
    useCallback,
    useLayoutEffect,
} from "react"
import "./configuration-panel.sass"
import { useSelector, useDispatch } from "react-redux"
import {
    getCurrentMicroUsecase,
    getAllMicroUsecases,
    updateCurrentMicroUsecase,
    getAllConfigurations,
} from "content-studio/src/features/micro-usecase"
import { Button, List, ListItem } from "@attrybtech/attryb-ui"
import { ConfigurationForm } from "content-studio/src/features/configuration-panel"
import { FiCheckCircle } from "react-icons/fi"
import { getProjectType } from "content-studio/src/features/project"
import { PROJECT_TYPE } from "content-studio/src/config"
import { useEffect } from "react"

const DEFAULT_COUNT = 5

const ConfigurationPanel = ({
    generateSuggestions,
    updateStep,
    currentProject,
}) => {
    const formRef = useRef(null)
    const radioInputRef = useRef(null)
    const selectInputRef = useRef(null)
    const dispatch = useDispatch()
    const usecasesFromDB = useSelector(getAllMicroUsecases)
    const usecaseConfigsFromDB = useSelector(getAllConfigurations)
    const activeUsecase =
        useSelector(getCurrentMicroUsecase) ?? Object.values(usecasesFromDB)[0]
    const projectType = useSelector(getProjectType)
    const [projectUsecasesConfig, setProjectUsecasesConfig] = useState(
        currentProject?.document?.usecase ?? {},
    )
    const [generateButtonState,setGenerateButtonState] = useState(false)
    const active = useSelector(getCurrentMicroUsecase)
    // const [selectedDropdownUsecase, setSelectedDropdownUsecase] = useState({})

    const collectionListComponent = Object.values(usecasesFromDB)
        .filter((usecase) => usecase.active)
        .map((usecase) => ({
            _id: usecase._id,
            value: usecase.name,
            data: usecase,
        }))

    const activeDropdownItem = useMemo(
        () => ({
            _id: activeUsecase?._id,
            value: activeUsecase?.name,
            data: activeUsecase,
        }),
        [activeUsecase],
    )

    const projectReadOnly = Object.keys(currentProject ?? {})?.length
        ? currentProject?.status !== "active"
        : false

    // const usecasesFromDBExists =
    //     usecaseConfigsFromDB && Object.keys(usecaseConfigsFromDB)?.length

    const projectConfigExist =
        projectUsecasesConfig &&
        !!Object.keys(projectUsecasesConfig).length &&
        !!projectUsecasesConfig?.current?.length

    const getDBUsecase = useCallback(
        (_id) => usecasesFromDB[_id],
        [usecasesFromDB],
    )

    const selectHandler = (dropdownItemDataObject) => {
        if (
            !dropdownItemDataObject ||
            !Object.keys(dropdownItemDataObject)?.length
        )
            return

        /**
         * TODO: Set current slice here instead of local usecase
         */

        // setProjectUsecasesConfig((prev) => ({
        //     ...prev,
        //     current: [dropdownItemDataObject?._id],
        // }))
        if(dropdownItemDataObject?._id !== activeDropdownItem?._id )
            dispatch(
                updateCurrentMicroUsecase(getDBUsecase(dropdownItemDataObject._id)),
            )
    }
    const getValueFilledUsecase = useCallback(
        (usecase) => {
            /**
             * Returns a usecase object with input values filled, if not values exist
             * for project's usecase config, then returns the same object with null
             * values for rendering input fields.
             */
            // if (!usecasesFromDBExists) return
            if (!projectConfigExist) return

            // TODO: selectedDropdownUsecase is defined in case of blank project but will be undefined as default in case of saved project
            // const usecase = activeUsecase //selectedDropdownUsecase?.data
            const numericalInputs = [
                "cons-count",
                "pros-count",
                "bullets-count",
                "titles-count",
            ]
            const savedValues = projectUsecasesConfig?.list[usecase?._id]
            const filledConfigs = usecase?.configurations?.map((configId) => {
                const config = { ...usecaseConfigsFromDB[configId] }
                config.value = savedValues ? savedValues[config.name] : ""

                if ((!config.value) && numericalInputs.includes(config.name))
                    config.value = DEFAULT_COUNT

                return config
            })
            const filledConfigObject = {
                ...usecase,
                configurations: filledConfigs,
            }
            return filledConfigObject
        },
        [projectConfigExist, projectUsecasesConfig?.list, usecaseConfigsFromDB],
    )

    const updateProjectUsecasesConfig = (values) => {
        const projectInitialValues = getInitialValueOfConfigs()
        /**
         * update the current active project list by concatenating it with project's initial values
         * and the updated values that triggers from input onChange event
         * then concatenate both previous list and new list with updated values in projectUseCasesConfig
         */
        const updatedList = {[activeUsecase?._id]:{...projectInitialValues, ...values[activeUsecase?._id]} }
        setProjectUsecasesConfig((prev) => ({
            ...prev,
            current: [activeUsecase?._id],
            list: { ...prev.list, ...updatedList },
        }))
    }
    useEffect(() => {
        /*
         * We have three type of input type in configuration panel
         * type = "input" || "radio" || "select"
         * State of generate button = true/default | false/disabled
         */
        let state = true
        activeUsecase?.configurations?.map((configId) => {
            const config = { ...usecaseConfigsFromDB[configId] }
            if (config.type === "input" && !config.optional) {
                var name = document.getElementById(config.name)?.value
                if (!name) return (state = false)
            }
            if (config.type === "radio" && !config.optional) {
                const listOfNode = radioInputRef?.current?.querySelectorAll("input")
                if(listOfNode?.length) {
                    const radioInputs = Array?.from(listOfNode)?.map((el) => {
                        return el.checked
                    })
                    //if there is no radio input checked, so update the state to false
                    const radioStateValue = !radioInputs.includes(true)
                    if (radioStateValue) return (state = false)
                }
            }
            if (config.type === "select" && !config.optional) {
                const listOfNode = selectInputRef?.current?.querySelectorAll("span")
                if(listOfNode?.length){
                    Array?.from(listOfNode)?.map(
                        (el) => {
                            if (el.innerText === "Select") return (state = false)
                        }
                    )
                }
            }
        })
        setGenerateButtonState(state)
    }, [updateProjectUsecasesConfig])

    const hasUsecaseBeenFilled = (usecase) => {
        return projectUsecasesConfig?.list
            ? Boolean(
                  Object.values(
                      projectUsecasesConfig?.list[usecase._id] ?? {},
                  )?.join("")?.length,
              )
            : false
    }

    useLayoutEffect(() => {
        if (activeUsecase?._id) {
            const projectValues = getInitialValueOfConfigs()
            /**
             * set PRE defined values of project 
             * for eg: title-count is set in initial values but that is not updated in projectUseCaseConfig
             */
            setProjectUsecasesConfig((prev) => ({
                ...prev,
                current: [activeUsecase?._id],
                list: { ...prev.list, ...projectValues },
            }))
        }
    }, [
        activeUsecase,
        dispatch,
        projectType,
        usecasesFromDB,
        currentProject,
        getDBUsecase,
    ])

    useLayoutEffect(() => {
        /**
         * Set the default current config to be same as the config obtained
         * from saved project's JSON and make them sync with each other.
         */
        const savedConfiguration = currentProject?.document?.usecase

        if (!savedConfiguration) return

        const deepCloneString = JSON.stringify(savedConfiguration)

        setProjectUsecasesConfig(JSON.parse(deepCloneString))
    }, [currentProject?.document?.usecase])

    const getInitialValueOfConfigs = ()=>{
        const preFillValues = getValueFilledUsecase(activeUsecase)
        const projectConfigs = {}
        if(preFillValues?._id && preFillValues?.configurations?.length ){
             preFillValues.configurations.map((config)=> {
                projectConfigs[config.name]=config.value
            } )
        }
        return projectConfigs
    }

    const handleFormSubmit = (event) =>{
        event.preventDefault()
        // check if the values exists in the form before submitting
        if(!(projectReadOnly || !generateButtonState)){
            generateSuggestions(projectUsecasesConfig)
            updateStep(1)
        }
    }
    
    return (
        <div className="ConfigurationPanel">
            <div className="usecase-wrapper" style={{ width: "100%" }}>
                <List
                    list={collectionListComponent}
                    activeItem={activeDropdownItem}
                    selectCallback={selectHandler}>
                    {collectionListComponent.map((item) => {
                        return (
                            <ListItem key={item._id} data={item}>
                                <div className="lhs">
                                    {item?.data.icon ? (
                                        <img
                                            className="usecase-icon"
                                            src={`/assets/icons/usecase/${item.data.name
                                                .toLowerCase()
                                                .split(" ")
                                                .join("-")}.svg`}
                                            alt={item?.data.description}
                                        />
                                    ) : null}
                                    <div className="text">{item?.value}</div>
                                </div>
                                <div className="rhs">
                                    {hasUsecaseBeenFilled(item) ? (
                                        <FiCheckCircle />
                                    ) : null}
                                </div>
                            </ListItem>
                        )
                    })}
                </List>
            </div>
            <div className="ConfigurationPanelBody">
                <form ref={formRef} onSubmit={handleFormSubmit} >
                    {Object.keys(usecasesFromDB)?.length ? (
                        <>
                       {
                         <ConfigurationForm
                            key={activeUsecase?._id}
                            usecase={getValueFilledUsecase(activeUsecase)}
                            projectReadOnly={projectReadOnly}
                            updateCallback={updateProjectUsecasesConfig}
                            radioInputRef={radioInputRef}
                            selectInputRef={selectInputRef}
                        />
                       }
                        </>
                    ) : null}
                    {/* button to handle form submit events */}
                    <Button className="Button form-submit hidden" type="submit" ></Button>
                </form>
            </div>
            <div className="ConfigurationPanelFooter">
                <Button
                    state={
                        projectReadOnly || !generateButtonState
                            ? "disabled"
                            : "default"
                    }
                    onClick={() => {
                        generateSuggestions(projectUsecasesConfig)
                        updateStep(1)
                    }}  
                    type="submit" >
                    {activeUsecase?.buttonLabel ?? "Generate"}
                </Button>
            </div>
        </div>
    )
}

ConfigurationPanel.propTypes = {}

export default ConfigurationPanel
