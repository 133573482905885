import React, { useState, useEffect } from "react"
import ReactDOMServer from "react-dom/server"
import {
    FiHeart,
    FiCopy,
    FiTrash2,
    FiMoreVertical,
    FiArrowRight,
    FiFlag,
    FiPlus,
    FiMinus,
} from "react-icons/fi"
import { BiCheckDouble } from "react-icons/bi"
import { getAllMicroUsecases } from "content-studio/src/features/micro-usecase"
import { mergeMovedContent } from "content-studio/src/features/content-editor"
import { useDispatch, useSelector } from "react-redux"
import { Menu, Badge } from "content-studio/src/components"
import { hash } from "content-studio/src/lib"
import OrgAvatar from "components/common_Components/orgAvatar/orgAvatar"
import { updateResultProp } from "content-studio/src/features/results"
import { useSuggestionOperations } from "content-studio/src/hooks"
import "./history-card.sass"

const HistoryCard = ({ history }) => {
    const dispatch = useDispatch()
    const microUsecases = useSelector(getAllMicroUsecases)
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

    const {
        textCopied,
        setTextCopied,
        updateFavoriteStatus,
        copyToContentEditor,
        copyToClipboard,
        getFormattedContent,
        expandable,
        isExpanded,
        setIsExpanded,
    } = useSuggestionOperations(
        history,
        dispatch,
        updateResultProp,
        mergeMovedContent,
    )

    const contextMenuList = [
        {
            id: hash.getUIDPrefixed("smenu-"),
            option: "Flag Content",
            icon: <FiFlag />,
        },
        {
            id: hash.getUIDPrefixed("smenu-"),
            option: "Remove",
            icon: <FiTrash2 />,
        },
    ]

    const handlerContextMenu = () => {
        setIsContextMenuOpen(false)
    }

    const contextMenuTriggerJSX = (
        <div
            className="ButtonRound ButtonRound--plain"
            data-pressed={isContextMenuOpen}>
            <FiMoreVertical />
        </div>
    )

    useEffect(() => {
        if (!textCopied) return
        const interval = setInterval(() => {
            setTextCopied(false)
        }, 1000)

        return () => clearInterval(interval)
    }, [textCopied, setTextCopied])

    return (
        <div className="HistoryCard">
            <div className="HistoryCardHeader">
                {microUsecases && Object.keys(microUsecases)?.length ? (
                    <Badge
                        key={hash.getRandom()}
                        size="sm"
                        text={microUsecases[history?.microUsecaseId]?.name}
                    />
                ) : null}

                {history?.isUsed > 0 ? (
                    <div
                        className="Icon-IsUsed"
                        title="This suggestion was used in Text Editor">
                        <BiCheckDouble />
                    </div>
                ) : null}
            </div>

            <div
                className="HistoryCardBody"
                data-expandable={expandable}
                data-expanded={expandable && isExpanded}>
                {getFormattedContent()}
                <div
                    data-expandable={expandable}
                    className="HistoryCardExpander"
                    onClick={() => setIsExpanded((prev) => !prev)}>
                    {isExpanded ? (
                        <>
                            <FiMinus /> <span>Less</span>
                        </>
                    ) : (
                        <>
                            <FiPlus /> <span>More</span>
                        </>
                    )}
                </div>
            </div>
            <div className="HistoryCardFooter">
                <div className="HistoryCardAuthor">
                    <div className="author-image" title={history?.author?.name}>
                        {history?.author?.image ? (
                            <img
                                src={history?.author?.image}
                                alt={`${history?.author?.name}'s profile`}
                            />
                        ) : (
                            <OrgAvatar
                                name={history?.author?.name}
                                className="author"
                            />
                        )}
                    </div>
                    <span></span>
                </div>
                <div className="HistoryCardActions">
                    <div
                        className="ButtonRound ButtonRound--plain"
                        title="Copy Text"
                        onClick={copyToClipboard}>
                        {textCopied ? (
                            <div className="CopyMessage font-small">
                                Text copied to clipboard
                            </div>
                        ) : null}
                        <FiCopy />
                    </div>
                    <div
                        className="ButtonRound ButtonRound--plain"
                        title="Mark Favorite"
                        data-fav={history?.isFavourite ? "true" : "false"}
                        onClick={updateFavoriteStatus}>
                        <FiHeart />
                    </div>
                    <div
                        className="ButtonRound ButtonRound--outline"
                        title="Mark Favorite"
                        onClick={copyToContentEditor}>
                        <FiArrowRight />
                    </div>

                    {false ? (
                        <Menu
                            list={contextMenuList}
                            align="right"
                            isOpen={isContextMenuOpen}
                            triggerButton={contextMenuTriggerJSX}
                            triggerClickCallback={() =>
                                setIsContextMenuOpen((prev) => !prev)
                            }
                            itemClickCallback={handlerContextMenu}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default HistoryCard
