/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from "framer-motion"
import React, { useEffect, useLayoutEffect, useState, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import "./project.sass"
import { useSelector, useDispatch } from "react-redux"
import { UseCaseBlog } from "content-studio/src/features/use-case-blog"
import { ProjectHeader } from "content-studio/src/features/project-header"
import {
    selectCurrentProject,
    getProjectStatus,
    getProjectError,
    fetchSingleProject,
    fetchSingleProjectDocument,
    createNewProject,
    resetStateStaus,
    clearCurrentProject,
    updateProject,
    updateProjectType,
} from "content-studio/src/features/project"
import { resetStep } from "content-studio/src/features/use-case-blog"
import { getContent } from "content-studio/src/features/content-editor"
import {
    fetchUsecaseResults,
    resetResultSlice,
    updateResultsProject,
    updateResultsSMeta,
} from "content-studio/src/features/results"
import { hash } from "content-studio/src/lib"

import { PROJECT_TYPE } from "content-studio/src/config"
import { FiArrowUp } from "react-icons/fi"


/**
 * Checks for the type of use case being opened - Content or Product based
 * and, renders appropriate component
 * @returns JSX.Element
 */

const WINDOW_SCROLL_THRESHOLD = 10

const Project = (props) => {
    const navigate = useNavigate();
    const { projectType } = props
    const { id } = useParams()
    const dispatch = useDispatch()
    const editorContent = useSelector(getContent)
    const mounted = useRef(false)
    const pageHeaderRef = useRef(null)

    /**
     * Stores the locally generated id to be sent to backend in order to create this draft a new project.
     * Uses uniqid to generate unique hash
     */
    // const [draftProjectId] = useState(id)
    /**
     * Data model of current object project
     */
    const currentProject = useSelector(selectCurrentProject)
    /**
     * Get the sync status of project. Can be idle, succeeded, failed, or loading
     */
    const projectStatus = useSelector(getProjectStatus)
    /**
     * Error message in case status changes to error
     */
    const error = useSelector(getProjectError)

    const [displayMoveToTop, setDisplayMoveToTop] = useState(false)

    const bannerAlerts = {
        archive:
            "This is an archived and read-only project. Unarchive to make changes.",
        trash: "This project is in Trash. Move it to 'Inbox' in order to restore it.",
    }

    // if (!mounted.current && currentProject?._id) {
    //     dispatch(clearCurrentProject())
    // }

    const [blankProjectName] = useState(
        `Untitled Project_${hash.getUID()?.slice(-3)}`,
    )

    const conditions = {
        hasCurrentProject: Boolean(
            currentProject && Object.keys(currentProject)?.length,
        ),
        hasNoCurrentProject: Boolean(
            !(currentProject && Object.keys(currentProject)?.length),
        ),
        hasNoParamId: Boolean(!id),
        isProjectStateIdle: Boolean(projectStatus === "idle"),
        isProjectStateNotIdle: Boolean(projectStatus !== "idle"),
        isProjectStateSuccess: Boolean(projectStatus === "succeeded"),
        isProjectStateError: Boolean(projectStatus === "error"),
    }

    const getProjectNameFromDOM = () => {
        return (
            pageHeaderRef?.current?.querySelector(".ProjectName")?.innerText ??
            null
        )
    }
    console.log("props?.location",  props?.location)

    const createProject = (currentConfig) => {
        /**
         * Preparing payload for our POST request
         */
        // TODO: On generating second time or refreshing, we are loosing this location state. Fix it
        
        let { teamId, teamName,productId } = props?.location?.state
        const payload = {
            name: getProjectNameFromDOM() ?? blankProjectName,
            usecase: { ...currentConfig },
            content: editorContent ?? {},
            /**
             * TODO: Get team and product info from state slice
             */
            teamId: teamId, 
            teamName,
            productId,
            productName: "Content Studio",
            icon: "blog_post.svg",
            status: "active",
            type: "Blog Post",
        }

        dispatch(
            createNewProject({
                payload: payload,
                dispatch: dispatch,
                fetchUsecaseResults: fetchUsecaseResults,
            }),
        )
    }

    const saveProject = (currentConfig) => {
        const payload = {
            _id: currentProject?._id,
            name: getProjectNameFromDOM() ?? blankProjectName,
            usecase: { ...currentConfig },
            content: editorContent ?? {},
        }

        dispatch(
            updateProject({
                payload: payload,
                dispatch: dispatch,
                fetchUsecaseResults: fetchUsecaseResults,
            }),
        )
    }

    const fetchResults = (currentConfig, projectId = currentProject?._id) => {
        if (!projectId) return
        const payload = {
            projectId: projectId,
            microUseCaseId: currentConfig?.current[0],
        }
        dispatch(fetchUsecaseResults(payload))
    }

    const generateSuggestions = (currentConfig) => {
        if (projectType === PROJECT_TYPE.BLANK) createProject(currentConfig)
        if (projectType === PROJECT_TYPE.EXISTING) saveProject(currentConfig)
    }

    useLayoutEffect(() => {
        dispatch(updateProjectType(projectType))
    }, [projectType])

    useLayoutEffect(() => {
        const resultsMeta = currentProject?.document?.result?.meta
        if (projectType === "blank" && currentProject?._id)
            navigate(`/project/${currentProject?._id}`,{replace:true})

        if (
            conditions.isProjectStateSuccess ||
            conditions.isProjectStateNotIdle
        )
            dispatch(resetStateStaus())

        if (currentProject?._id)
            dispatch(updateResultsProject(currentProject?._id))

        if (resultsMeta) dispatch(updateResultsSMeta(resultsMeta))
    }, [currentProject])

    useEffect(() => {
        if (projectType === PROJECT_TYPE.BLANK) return
        // if (conditions.isProjectStateNotIdle) return dispatch(resetStateStaus())

        if (conditions.isProjectStateError) {
            alert(error)
        }

        const config = { id: id, documentonly: true }

         dispatch(fetchSingleProject({ ...config }))

        /**
         * TODO: Update to new cache points to fetch the
         * persisted project correctly from session storage.
         */
        dispatch(fetchSingleProjectDocument(config))
    }, [])

    /**
     * Following code mimics the component unmount event handler
     */
    useEffect(() => {
        mounted.current = true

        const windowScrollHandler = () => {
            const hasScrolled = window.scrollY > WINDOW_SCROLL_THRESHOLD
            if (hasScrolled) setDisplayMoveToTop(hasScrolled)
        }

        window.addEventListener("scroll", windowScrollHandler)
        return () => {
            dispatch(clearCurrentProject())
            dispatch(resetResultSlice())
            dispatch(resetStep())
            window.removeEventListener("scroll", windowScrollHandler)
            mounted.current = false
        }
    }, [])

    return (
        <div className="Project" ref={mounted}>
            <motion.div
                className="motion-div"
                animate={{ y: 0 }}
                initial={{ y: 100 }}
                transition={{
                    type: "spring",
                    stiffness: 100,
                    bounce: 0.2,
                    mass: 0.5,
                    damping: 10,
                }}>
                <div className="Page">
                    <ProjectHeader parentRef={pageHeaderRef} />
                    {currentProject?.status &&
                    currentProject?.status !== "active" &&
                    currentProject?._id ? (
                        <div
                            className="banner-alert"
                            data-status={currentProject?.status}>
                            {bannerAlerts[currentProject?.status]}
                        </div>
                    ) : null}
                    {/* In future, add condition here to render specific page.
                     * For example product page, usecase blog (current case)
                     */}
                    <UseCaseBlog
                        projectStatus={projectStatus}
                        currentProject={currentProject}
                        fetchResults={fetchResults}
                        generateSuggestions={generateSuggestions}
                    />
                    {displayMoveToTop ? (
                        <div
                            className="MoveToTop"
                            onClick={() => {
                                window.scrollTo(0, 0)
                                setDisplayMoveToTop(false)
                            }}>
                            <FiArrowUp />
                        </div>
                    ) : null}
                </div>
            </motion.div>
        </div>
    )
}

export default Project
