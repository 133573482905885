/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { FiFileText, FiClock, FiSliders } from "react-icons/fi"
import { BsDot } from "react-icons/bs"
import { ConfigurationPanel } from "content-studio/src/features/configuration-panel"
import {
    ResultsList,
    getResultsMeta,
} from "content-studio/src/features/results"
import { hash } from "content-studio/src/lib"
import {
    fetchResultsHistory,
    HistoryList,
} from "content-studio/src/features/history"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentMicroUsecase } from "content-studio/src/features/micro-usecase"
import "./use-case-blog-sidebar.sass"
import CONFIG from "data/config.json"
const {RESULTS_SCROLLABLE_LIST_ID} = CONFIG

const UseCaseSidebarBlog = ({
    generateSuggestions,
    currentStep,
    switchTabTo,
    USECASE_SIDEBAR_TABS,
    currentProject,
    projectStatus,
    iconCollapse,
}) => {
    const currentMicroUsecase = useSelector(getCurrentMicroUsecase)
    const metaData = useSelector(getResultsMeta)

    const resultsMetadata = Object.keys(metaData ?? {})?.length
        ? metaData
        : currentProject?.document?.result?.metaData

    const resultsCount = Object.keys(resultsMetadata ?? {})?.length
        ? resultsMetadata[currentMicroUsecase?._id]
        : 0

    // const historyCount = resultsMetadata
    //     ? Object.values(resultsMetadata).reduce((acc, val) => acc + val)
    //     : 0

    const historyCount = 0 // TODO: Update it like we are updating resultsCount

    const tabsContainerRef = useRef(null)
    const scrollableListRef = useRef(null)
    const dispatch = useDispatch()
    /**
     * Using this to map tabs with their corresponding icons
     * based on tab's name
     */
    const tabIcon = {}
    tabIcon[USECASE_SIDEBAR_TABS.CONFIG] = <FiSliders />
    tabIcon[USECASE_SIDEBAR_TABS.RESULTS] = <FiFileText />
    tabIcon[USECASE_SIDEBAR_TABS.HISTORY] = <FiClock />

    const setTabsContainerScroll = () => {
        const winHeight = window.innerHeight
        const yOffset = tabsContainerRef.current?.offsetTop
        const containerHeight = winHeight - yOffset
        /**
         * Tab container height should be absolute with value equivalent to
         * the difference obtained when we subtract tab container's top
         * offset from the window's overall height
         */
        tabsContainerRef.current.style.height = containerHeight + "px"
    }

    const renderTabs = () => {
        return Object.keys(USECASE_SIDEBAR_TABS).map(function (tab) {
            const stepName = USECASE_SIDEBAR_TABS[tab]
            return (
                <div
                    key={hash.getUID()}
                    className="Tab"
                    data-active={currentStep === stepName}
                    onClick={() => switchTabTo(stepName, resultsCount ?? false)}>
                    {tabIcon[stepName]}
                    <span className="TabName">
                        {stepName}
                        {stepName === "results"
                            ? ` (${resultsCount ?? 0})`
                            : null}
                        {stepName === "history" ? <>({historyCount})</> : null}
                    </span>
                </div>
            )
        })
    }

    useEffect(() => {
        /**
         * If current step is "History" tab, we fetch results
         * history if not already fetched
         */
        if (currentStep !== "history") return
        if (!currentProject?._id) return
        dispatch(fetchResultsHistory(currentProject?._id))
    }, [currentStep])

    useEffect(() => {
        /**
         * Fix to clear the text selection that's happening
         * when opens sidebar for first time
         */
        window.getSelection()?.removeAllRanges()
    }, [])

    useEffect(() => {
        setTabsContainerScroll()
    }, [])

    return (
        <div className="UseCaseSidebarBlog">
            <div className="TabsContainer" ref={tabsContainerRef}>
                <div className="TabsGroup">
                    {renderTabs()} {iconCollapse}
                </div>
                <div className="TabBody" ref={scrollableListRef} id={RESULTS_SCROLLABLE_LIST_ID} >
                    {currentStep === USECASE_SIDEBAR_TABS.CONFIG ? (
                        <ConfigurationPanel
                            key={hash.getUID()}
                            generateSuggestions={generateSuggestions}
                            updateStep={() =>
                                switchTabTo(USECASE_SIDEBAR_TABS.RESULTS,false)
                            }
                            currentProject={currentProject}
                        />
                    ) : null}
                    {currentStep === USECASE_SIDEBAR_TABS.RESULTS ? (
                        <ResultsList />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

UseCaseSidebarBlog.propTypes = {
    list: PropTypes.array,
    generateSuggestions: PropTypes.func,
    currentStep: PropTypes.string,
    switchTabTo: PropTypes.func,
    USECASE_SIDEBAR_TABS: PropTypes.object,
    resultsCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iconCollapse: PropTypes.element,
    currentProject: PropTypes.object,
}

export default UseCaseSidebarBlog
