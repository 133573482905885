import { configureStore } from "@reduxjs/toolkit"
import { projectReducer } from "content-studio/src/features/project"
import { microUsecaseSlice } from "content-studio/src/features/micro-usecase"
import { userFilesReducer } from "content-studio/src/features/user-files"
import { ContentEditorReducer } from "content-studio/src/features/content-editor"
import { usecaseBlogReducer } from "content-studio/src/features/use-case-blog"
import { historyReducer } from "content-studio/src/features/history"
import { resultReducer } from "../features/results"

/**
 * Need to create following slices in store
 *  - app: To manage state of global app
 *  - user: To manage user in active session
 *  - project: Currently active project if any
 *  - projects: list of all projects accessible/viewable by this user
 *  - favorites: list of all content favorite by this user
 *  - usecases: list of all usecases
 */

const store = configureStore({
    reducer: {
        project: projectReducer,
        microUsecase: microUsecaseSlice,
        userFiles: userFilesReducer,
        contentEditor: ContentEditorReducer,
        usecaseBlog: usecaseBlogReducer,
        history: historyReducer,
        result: resultReducer,
    },
})

export default store
