import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom/client"
// import App from './App';
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import "./components/styles/commonStyles/new-layout.css"
import "./components/styles/css-lib/typography.css"
import "./components/styles/css-lib/spacing.css"
import "./components/styles/css-lib/color-palette.css"
import "./components/styles/css-lib/class-lib.css"
// import './components/styles/css-lib/elements/buttons.css';
import "./index.css"
import LoaderScreen from "./LoaderScreen/LoaderScreen"
import Store from "./content-studio/src/stores/Store"

import { Provider } from "react-redux"
import store from "content-studio/src/store/store"
import PouchDb from "pouchdb-browser"
import { hash } from "content-studio/src/lib"
import "./auth/configurations/amplify-init"

const App = lazy(() => {
    return import("./App0")
})
/**
 * Initialize non-react stuff that is needed by app to
 * work properly
 */
const test = async () => {
    let db = new PouchDb("dbname")
    const id = hash.getRandom()
    await db.put({
        _id: id,
        today: new Date()?.toISOString(),
    })

    await db.post({
        today: new Date()?.toISOString(),
    })
    const d = await db.get(id)
    const list = await db.allDocs({
        include_docs: true,
        attachments: true,
    })

    // await db.destroy()
}

// test()


ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
    <Suspense fallback={<LoaderScreen />}>
        <Store>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </Store>
</Suspense>
    </React.StrictMode>
)